.container {
  composes: darkPage from '../../styles/darkPage.css';
}

.logo {
  composes: logo from '../../styles/logo.css';
}

:global(.isChromeless) .container {
  background: transparent;
}

:global(.isChromeless) .content {
  background: transparent;
  padding: 0;
  color: var(--text);
}

:global(.isChromeless) .logo {
  margin: 0;
}