.container {
  composes: contentCell from '../../styles/contentCell.css';
  margin: auto;
}

.container h1 {
  margin-top: 0;
}

@media (--large-device) {
  .container {
    width: 90%;
  }
}