.container {
  padding: 20px 0;
  border: 1px solid var(--gridline);
}

.container:only-child {
  padding: 0;
}

.cta {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  border-top: 1px dashed var(--neutral);
  padding-top: 30px;
  margin-top: 20px;
  text-align: center;
}

.cta > div {
  max-width: var(--bpSmall);
}

.cta > div:only-child {
  grid-column-end: span 2;
}

.cta > div:not(:only-child) {
  justify-self: start;
}

.container:only-child > .cta:only-child {
  padding-top: 0;
  border-top: none;
}

.icon {
  fill: var(--neutral);
  margin-right: 40px;
  justify-self: end;
}

.header {
  font-size: 2.2rem;
  margin-bottom: 0;
}

.icon[name='settings'] {
  width: 100px;
  height: 100px;
}