.tooltip {
  opacity: 1 !important;
  background-color: var(--popoverBackground) !important;
  color: var(--popoverText) !important;
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 0 2px 10px);
  max-width: 425px;
  text-align: left;
}

.content {
  display: grid;
  height: 100%;
}

.isInline,
.isInline .content {
  display: inline-block;
}

.tooltip:global(.__react_component_tooltip) {
  padding: 8px;
}

.tooltip:global(.place-top):after {
  border-top-color: var(--popoverBackground) !important;
}

.tooltip:global(.place-right):after {
  border-right-color: var(--popoverBackground) !important;
}

.tooltip:global(.place-bottom):after {
  border-bottom-color: var(--popoverBackground) !important;
}

.tooltip:global(.place-left):after {
  border-left-color: var(--popoverBackground) !important;
}
