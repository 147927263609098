.small,
:global(.material-icons).small {
  font-size: 20px;
}

.medium,
:global(.material-icons).medium {
  font-size: 24px;
}

.large,
:global(.material-icons).large {
  font-size: 30px;
}

.info {
  color: var(--info);
}

.success {
  color: var(--success);
}

.warning {
  color: var(--warning);
}

.error {
  color: var(--error);
}