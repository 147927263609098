.container {
  composes: contentCell from '../../styles/contentCell.css';
  margin: auto;
}

.muted {
  color: var(--neutral);
  padding: 0 5px;
}

@media (--large-device) {
  .container {
    width: 90%;
  }
}