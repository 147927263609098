.row {
  display: grid;
  justify-items: start;
}

.isPair {
  grid-template-columns: 30% auto 35px;
}

.isSingle {
  grid-template-columns: auto 35px;
}

.row > div:first-child,
.row > input:first-child,
.row > select:first-child {
  max-height: 35px;
  width: calc(100% - 5px);
}

.row > div:not(:first-child) {
  width: 100%
}

.iconRemove {
  cursor: pointer;
  justify-self: center;
  width: 22px;
  margin-top: 4px;
  fill: var(--neutral);
  transition: opacity 200ms, fill 100ms;
}

.iconRemove:not(.iconRemoveDisabled):hover {
  fill: var(--accent);
}

.iconRemoveDisabled {
  cursor: default;
  opacity: 0.2;
}
