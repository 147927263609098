.container {
  padding: 20px 0 0;
}

.container:first-child {
  padding-top: 0;
}

.alignCenter {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}
