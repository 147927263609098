.container {
  display: inline-block;
  margin: 0 3px;
  width: 13px;
  height: 13px;
  position: relative;
}

.iconCircle {
  width: 100%;
  height: auto;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 2px;
  fill: var(--accent);
}