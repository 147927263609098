.container {
  composes: content from '../stack/Stack.css';
}

.main {
  overflow: auto;
  position: relative;
}

.isPanelOpen .main {
  overflow: hidden;
}

.sidebar {
  composes: sidebar from '../stack/Stack.css';
}

.sidebar p {
  color: var(--neutral);
}

.deploymentsList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.deploymentItem {
  cursor: pointer;
  margin: 5px 0;
  padding: 10px 5px;
  border: 1px solid var(--gridLines);
  border-radius: 2px;
  position: relative;
}

.deploymentItem:not(.isSelected):hover {
  background-color: var(--canvas);
}

.deploymentItem.isSelected {
  background-color: var(--nodeStroke);
  border-color: transparent;
  border-radius: 2px;
}

.deploymentItem.isSelected:before {
  content: '';
  position: absolute;
  z-index: 1;
}

.deploymentItem.isSelected:before {
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 9px solid var(--nodeStroke);
  top: calc(50% - 9px);
  right: -9px;
}
