@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
 src: url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNY.eot)
      format('eot'),
    url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNZ.ttf)
      format('truetype'),
    url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNa.woff)
      format('woff'),
    url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
      format('woff2');
}

h1,
.headingLarge {
  font-size: 2.5em;
  margin: .67em 0;
  font-weight: normal;
  margin: 10px 0;
}

h2,
.headingMedium {
  font-size: 2rem;
  font-weight: normal;
}

h3,
h4,
.headingSmall {
  font-size: 1.8rem;
  font-weight: normal;
}

.smallCaps {
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.1rem;
}

.label {
  composes: smallCaps;
  margin-bottom: 5px;
  color: var(--text);
}

.timestamp {
  font-style: italic;
  font-size: 1.4rem;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
 src: url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNY.eot)
      format('eot'),
    url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNZ.ttf)
      format('truetype'),
    url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNa.woff)
      format('woff'),
    url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
      format('woff2');
}
