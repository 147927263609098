.titleBar {
  fill: var(--node);
  stroke: var(--nodeStroke);
  stroke-width: 1px;
}

.well {
  rx: 1;
  ry: 1;
  fill: var(--nodeWell);
  stroke: var(--nodeStroke);
}

.iconNode {
  fill: var(--nodeText);
}

.label {
  composes: label from '../../styles/type.css';
  font-size: 1rem;
  color: var(--nodeLabel);
  fill: var(--nodeLabel);
}

.name {
  fill: var(--nodeText);
  text-anchor: start;
  font-style: normal;
}

.nameRight {
  composes: name;
  text-anchor: end;
}

.resize {
  fill: transparent;
}

.resizeVertical {
  composes: resize;
  cursor: row-resize;
}

.resizeHorizontal {
  composes: resize;
  cursor: col-resize;
}

.iconBackground {
  fill: var(--node);
}

.iconAsterisk {
  fill: var(--nodeText);
  position: absolute;
  right: 0;
  top: 0;
}

.iconAlert {
  fill: var(--nodeText);
  position: absolute;
  right: 0;
  top: 0;
}

.metricsGroup {
  font-size: 11px;
  text-transform: capitalize;
}


/** .isCustom **/
.isCustom .well {
  stroke: var(--customNode);
}

.isCustom .titleBar {
  fill: var(--customNode);
  stroke: var(--nodeStroke);
}

.isCustom .iconBackground {
  fill: var(--customNode);
}


/** .isDirty **/
.isDirty .name {
  font-style: italic;
}


/** .hasErrors **/
.hasErrors .well {
  stroke-dasharray: 1px;
  stroke: var(--error);
}


/** .isSelected **/
.isSelected .well {
  stroke: var(--nodeHighlight);
}

.isSelected .titleBar {
  stroke: var(--nodeHighlight);
  fill: var(--nodeHighlight);
}

.isSelected .iconNode {
  fill: var(--nodeTextHighlight);
}

.isSelected .name {
  fill: var(--nodeTextHighlight);
}

.isSelected .label {
  color: var(--nodeTextHighlight);
  fill: var(--nodeTextHighlight);
}

.isSelected .iconBackground {
  fill: var(--nodeHighlight);
}

.isSelected .iconAsterisk {
  fill: var(--nodeTextHighlight);
}

.isSelected .iconAlert {
  fill: var(--nodeTextHighlight);
}

.isSelected.isCustom .well {
  stroke: var(--nodeHighlight);
}

.isSelected.isCustom .titleBar {
  fill: var(--nodeHighlight);
  stroke: var(--nodeHighlight);
}
