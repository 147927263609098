.container {
  background: url('../../images/arrows-up-down-dark.svg') calc(100% - 10px) / 7px no-repeat;
  display: block;
  width: 100%;
  border-radius: 2px;
  background-color: var(--inputBackground);
  border: 1px solid var(--inputBorder);
  height: 35px;
  color: var(--inputText);
  appearance: none;
  padding: 2px 20px 2px 8px;
}

.container:after {
  position: absolute;
  right: 0;
  top: 0;
}

.container.isDisabled {
  background-color: var(--inputBackground);
}

.hasError {
  border: 1px solid var(--error);
}

@media (prefers-color-scheme: dark) {
  .container {
    background-image: url('../../images/arrows-up-down-light.svg');
  }
}
