.container {
  margin-top: 10px;
}

.container:nth-child(odd) {
  margin-right: 10px;
}

.node {
  cursor: pointer;
  display: grid;
  align-content: center;
  align-items: center;
  text-align: left;
  height: 36px;
  padding-left: 45px;
  background-color: var(--node);
  border-radius: 2px;
  border: 2px solid var(--nodeStroke);
  color: var(--nodeText);
  position: relative;
  width: 100%;
}

.node.isDraggable {
  cursor: move;
}

.nodeUnavailable {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.65;
  user-select: none;
}

.icon {
  position: absolute;
  left: 2px;
  top: 2px;
}

.icon svg,
.iconRight svg {
  fill: var(--nodeText);
  stroke: none;
  margin: 5px 5px 0 6px;
  width: 22px;
  height: 22px;
  position: absolute;
  z-index: 1;
}

.iconRight {
  composes: icon;
  left: auto;
  right: 2px;
  order: 1;
}

.hint {
  color: var(--text);
  font-size: 1rem;
  margin: 0;
}

.label {
  composes: label from '../../styles/type.css';
  font-size: 1rem;
  color: var(--nodeLabel);
  margin: 0;
}

.name {
  color: var(--nodeText);
  margin: 0;
}

.node:hover,
.node:hover .icon:before {
  background-color: var(--nodeHighlight);
}

.node:hover,
.node:hover .name,
.node:hover .label,
.node:hover .icon svg {
  color: var(--nodeTextHighlight);
  fill: var(--nodeTextHighlight);
}

@media (--medium-device) {
  .container:nth-child(odd) {
    margin-right: 0;
  }
}