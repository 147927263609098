.container {
  overflow: auto;
  padding: 20px 20px 60px;
}

.header,
.footer {
  max-width: var(--bpXlarge);
}

.header {
  display: grid;
  grid-template-columns: auto max-content;
  margin-bottom: 20px;
}