.container {
  padding-bottom: var(--appPaddingBottom);
}

.editorContainer {
  padding: 20px 0;
  display: grid;
  grid-template-rows: 50vh max-content;
  max-height: 500px;
  min-height: 200px;
}

.editor {
  height: 100%;
  padding-bottom: 10px;
}

.saveButton {
  width: 250px;
  justify-self: end;
}
