.container {
}

.header {
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
  color: var(--neutral);
  font-weight: normal;
  margin-bottom: 3px;
}

.event {
  composes: smallCaps from '../../styles/type.css';
}

.timestamp {
  composes: timestamp from '../../styles/type.css';
}

.capitalize {
  text-transform: capitalize;
}