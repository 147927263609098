.container {
  display: grid;
  grid-template-columns: 65% 35%;
  height: calc(100vh - var(--appPaddingTop));
}

.header {
  margin: 0 0 40px;
}

.main {
  padding: 10px 40px 20px 50px;
}

.sidebar {
  color: var(--textMuted);
  padding: 20px 40px;
  max-width: 800px;
  border-left: 1px solid var(--gridLines);
  background-color: var(--canvas);
  box-shadow: 0px 0px 20px 0px var(--insetShadow) inset;
}

@media (--medium-device) {
  .main {
    padding-right: 20px;
    padding-left: 20px;
  }

  .sidebar {
    padding: 20px;
  }
}

@media (--small-device) {
  .container {
    grid-template-rows: auto auto;
    grid-template-columns: none;
  }

  .sidebar {
    border-left: none;
    border-top: 1px solid var(--gridLines);
    margin-top: 20px;
  }
}