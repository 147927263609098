.container {
  padding: 7px;
  margin-top: -2px;
  margin-left: 0;
}

.container:first-child {
  margin-left: -7px;
}

.container:not(:only-child) {
  margin-right: 2px;
}

.container:hover {
  background-color: var(--inputBackground);
}

.container svg {
  height: 20px;
  width: 20px;
}

.colorPrimary {
  color: var(--neutral);
}

.colorPrimary.isSelected {
  color: var(--accent);
}

.colorPrimary.isDisabled {
  color: var(--disabledInputText);
}
