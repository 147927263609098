.container {
  composes: container from '../core/Input.css';
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: start;
  padding: 10px;
  margin-bottom: 2px;
}

.container > span {
  margin-left: 10px;
}

.container:last-child,
.container:last-of-type {
  margin-bottom: 0;
}

.label {
  color: var(--inputText);
}

.hint {
  composes: hint from '../core/FormRow.css';
  color: var(--contentDarkMutedText);
  margin-top: 2px;
}
