.container {
  display: flex;
  z-index: calc(var(--zIndexMax) + 1);
  position: fixed;
  align-items: center;
  justify-content: center;
  left: 50%;
  right: auto;
  bottom: 2px;
  max-width: 600px;
  height: 100px;
  transform: translateX(-50%);
  perspective: 1450px;
  perspective-origin: 50% -50%;
}

.item {
  position: absolute;
  margin-top: 1px;
  opacity: 0;
  backface-visibility: hidden;
  transform: translateX(-50%) translate3d(0px, 0px, -25px);
  transition: opacity 200ms 50ms, transform 300ms;
  width: 100%;
  top: auto;
  bottom: 0;
}

.content {
  position: relative;
  z-index: auto;
}