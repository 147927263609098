.container {
  margin-bottom: 50px;
}

.header {
  display: grid;
  grid-template-columns: auto 1fr;
  background-color: var(--body);
  align-items: center;
  padding: 0 10px 10px 10px;
  margin-bottom: 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 3;
  box-shadow: 0px 4px 4px -4px var(--borderShadow);
}

.title {
  text-transform: capitalize;
  padding-right: 50px;
  margin: 0 50px 0 0;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.label {
  composes: smallCaps from '../../styles/type.css';
  display: inline-block;
  color: var(--neutral);
}

.label:not(:first-child) {
  margin-left: 10px;
}

.scrollArea {
  overflow: auto;
  border: 1px solid var(--rowBorder);
  border-radius: 2px 2px 0 0;
  width: 100%;
  max-height: 80vh
}

.table {
  border-collapse: unset;
  border-spacing: 0;
  width: 100%;
  height: 1px; /* required so td div will fill height of cell :fun: */
  table-layout: fixed;
}

.tableHeader {
  box-shadow: 0px 3px 3px -3px var(--borderShadow);
}

.tableBody td {
  vertical-align: top;
  height: 100%;
  padding: 0;
}

.tableBody td:not([class]) {
  padding: 20px;
  text-align: center;
}

.stackRowHeader th {
  border-bottom: 1px solid var(--rowBorder);
}

.stackRowHeader:not(:first-child) th {
  border-top: 1px solid var(--rowBorder);
}

.stackRowBody td:not(:last-child),
.stackRowBody td:only-child {
  border-right: 1px dashed var(--rowBorder);
}

.stageHeader {
  background-color: var(--rowShade);
  border-bottom: 1px solid var(--rowBorder);
  padding: 20px;
  font-weight: normal;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}

.stageHeader:not(:last-child) {
  border-right: 1px dashed var(--rowBorder);
}

.stageHeader h2 {
  margin: 0 0 5px;
}

.stackHeader {
  padding: 25px 0 10px;
}

.stackHeader.isFixed > div {
  position: sticky;
  left: 0;
  width: 100%;
}

.stackHeader a {
  display: inline-block;
}

.stackHeader h3 {
  margin: 0;
}

@media (--large-device) {
}