
.container {
  composes: container from './Drawer.css';
  background-color: var(--inputBackground);
  border: 1px solid var(--inputBorder);
  border-radius: 2px;
  padding: 0;
  margin: 0;
}

.content {
  composes: content from './Drawer.css';
  padding: 6px;
}

.label {
  composes: label from './FormRow.css';
  margin: 0;
}

.diff {
  min-width: 430px;
  width: auto;
  height: 400px;
  overflow: scroll;
}
