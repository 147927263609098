.container {
  display: block;
  color: var(--link);
  padding: 8px 20px;
  font-size: 1.4rem;
  position: relative;
  white-space: nowrap;
}

.container:hover,
.container:focus,
.container.isActive {
  background-color: var(--rowHover);
}

.iconChevron {
  width: 15px;
  position: absolute;
  left: 3px;
  top: calc(50% - 1px);
  transform: translateY(-50%);
}