.container {
  margin: 14px 0;
  position: relative;
}

.alignCenter {
  text-align: center;
}

.displayInline .primary,
.displayInline .secondary {
 display: inline-block;
}

.displayInline .primary {
  margin-right: 5px;
}

.primary,
.secondary {
  margin: 0;
}

.primary {
  font-weight: bold;
  font-size: 2.6rem;
}

.secondary {
  font-weight: normal;
  font-size: 2.5rem;
}
