.container {
  display: grid;
  grid-template-rows: var(--subHeaderHeight) 1fr;
  width: 100%;
  height: calc(100vh - var(--appPaddingTop));
}

:global(.isDemoMode) .container {
  height: calc(100vh - calc(var(--appPaddingTop) + var(--demoBannerHeight)));
}

.main {
  overflow: hidden;
  position: relative;
  display: grid;
  grid-template-columns: var(--subNavWidth) 1fr;
}

.content {
  padding: 0 20px;
  overflow: auto;
}

.content > div {
  max-width: var(--bpLarge);
}
