.container {
  composes: sidebar from '../stack/Stack.css';
}

.nav {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid var(--gridLines);
  border-radius: 2px;
  margin-bottom: 20px;
}

.navLink,
.navLink:visited {
  color: var(--neutral);
}

.navLink {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 0;
  padding: 10px;
  text-decoration: none;
  font-size: 1.3rem;
  border-right: 1px solid var(--gridLines);
}

.navLink:last-child {
  border: none;
}

.activeNavLink {
  background-color: var(--inputBackground);
  font-weight: bold;
}

.activeNavLink,
.activeNavLink:visited,
.navLink:hover {
  color: var(--accent);
}

.label {
  composes: label from '../../styles/type.css';
  margin-top: 0;
}

.message {
  composes: fadeIn from '../../styles/keyframes.css';
  margin: 10px 0;
  color: var(--neutral);
  font-style: italic;
}

.cta {
  border-top: 1px solid var(--gridLines);
  margin-top: 30px;
  padding-top: 20px;
}