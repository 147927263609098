.container {
  display: block;
}

.container.hasFilter,
.container.hasFieldset {
  display: grid;
  grid-template-columns: 1fr max-content;
}

.container.hasFilter.hasFieldset {
  display: grid;
  grid-template-columns: 1fr max-content max-content;
}

.input {
  composes: container from './Input.css';
}

.select {
  composes: container from './Select.css';
  position: relative;
  right: 1px;
  height: 39px;
  width: auto;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.fieldset {
  align-self: center;
  justify-self: end;
  margin: 0 0 0 20px;
  border: 0;
  padding: 0;
}