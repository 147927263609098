.container,
.content {
  display: grid;
  align-items: end;
}

.container {
  composes: container from '../core/Link.css';
  background: var(--inverse);
  box-shadow: 0 0 8px -4px var(--alpha-black-3);
  padding: 15px 20px;
  border: 1px solid var(--gridLines);
  border-radius: 4px;
  text-align: center;
  margin: auto;
}

.container:not(a) {
  cursor: default;
}

.container:hover,
.container.isLinked {
  border-color: var(--linkHover);
}

.container.isLinked {
  color: var(--text);
}

.container.isGroup {
  margin: 0 10px 0 0;
}

.container.isGroup:last-child {
  margin: 0;
}

.iconProvider {
  align-self: start;
  height: 50px;
  width: auto;
  fill: var(--link);
  margin: 0 auto 8px;
}

.container:hover .iconProvider {
  fill: var(--linkHover);
}

@media (--small-device) {
  .container,
  .container:last-child {
    width: 90%;
    margin: auto auto 10px;
  }
}