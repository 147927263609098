.container {
  counter-reset: step;
  display: grid;
  grid-template-columns: max-content max-content max-content;
  margin: 0 0 20px;
  padding: 0;
}

.navItem {
  display: inline-block;
  border: 1px solid var(--gridLines);
  border-right-width: 0px;
}

.navItem:first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.navItem:last-child {
  border-right-width: 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.navLink {
  composes: container from '../core/Link.css';
  background-color: var(--canvas);
  display: block;
  padding: 15px 15px 15px 22px;
  font-size: 1.6rem;
  position: relative;
}

.navLink,
.navLink:visited {
 color: var(--neutral);
}

.navLink:hover {
  color: var(--accent);
}

.navItem:first-child .navLink {
  padding-left: 15px;
}

.navLink:before {
  content: counter(step) '.';
  counter-increment: step;
  margin: 0 5px 0 0;
}

.navItem.isComplete .navLink:before {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.navItem:not(:last-child) .navLink:after {
  content: '';
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 13px solid var(--gridLines);
  position: absolute;
  right: -13px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.navItem.isComplete .navLink {
  padding-left: 39px;
}

.navItem.isComplete:first-child .navLink {
  padding-left: 30px;
}

.activeNavLink {
  background-color: var(--inverse);
}

.activeNavLink,
.activeNavLink:visited,
.activeNavLink:hover {
  color: var(--accent);
}

.activeNavLink:before {
  font-weight: bold;
}

.iconCheckmark {
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  height: 21px;
  width: auto;
  fill: var(--accent);
}

.iconTriangle {
  position: absolute;
  right: -12px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  height: 21px;
  width: auto;
  fill: var(--canvas);
}

.activeNavLink .iconTriangle {
  fill: var(--inverse);
}

.navItem:first-child .iconCheckmark {
  left: 7px;
}

@media (--small-device) {
  .navItem:first-child .navLink {
    padding-left: 9px;
  }

  .navLink {
    padding-right: 7px;
  }

  .navItem.isComplete .navLink {
    padding-left: 37px;
  }

  .iconCheckmark {
    left: 13px;
  }
}
