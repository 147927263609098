.container {
  display: grid;
  grid-template-rows: max-content max-content max-content max-content max-content;
  border-right: 1px solid var(--gridLines);
  padding: 15px 0;
}

.navLink,
.navLink:visited {
  color: var(--neutral);
}

.navLink {
  display: grid;
  grid-template-columns: 22px 1fr;
  align-items: center;
  margin: 1px 0 10px 0;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1.3rem;
  padding-left: var(--sidebarPadding);
}

.activeNavLink,
.activeNavLink:visited,
.navLink:hover {
  color: var(--accent);
}

.activeNavLink {
  font-weight: bold;
  position: relative;
}

.activeNavLink:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 2px;
  background: var(--accent);
  display: block;
  right: 0;
  top: 0;
  transform: translateX(1.5px);
}

.icon {
  margin-right: 2px;
  width: 17px;
}

.iconSend {
  transform: rotate(-45deg);
  position: relative;
  top: -2px;
}

.iconOverview {
  width: 19px;
}
