:root {
  --width: 600px;
  --transition: right 100ms ease;

  @media (--medium-device) {
    --width: 400px;
  }
}

.container {
  position: fixed;
  top: 0;
  bottom: 1px;
  right: 0;
  z-index: 1;
}

.container:after {
  content: '';
  width: 5px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  box-shadow: -11px 5px 26px 0px rgba(0, 0, 0, 0.5);
  z-index: -1;
  transition: var(--transition);
}

.shade {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--shade);
}

.panel {
  position: absolute;
  top: 0;
  bottom: 0;
  right: calc(var(--width) * -1);
  width: var(--width);
  transition: transition;
  background: var(--body);
  padding: 30px 20px 80px;
  color: #132e3c;
  overflow-y: auto;
  overflow-x: hidden;
  border-left: 1px solid var(--gridLines);
}

.panel p:not([class]) {
  color: var(--text);
}

.close {
  cursor: pointer;
  fill: var(--neutral);
  width: 35px;
  height: 35px;
  transform: rotate(45deg);
  position: absolute;
  right: 10px;
  top: 10px;
}

.close:hover {
  fill: var(--accent);
}

.isOpen .panel {
  right: 0;
}

.isOpen:after {
  right: calc(var(--width) - 15px);
}

@media (--x-small-device) {
  .panel {
    width: 100vw;
    border-left: none;
  }
}