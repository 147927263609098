.container {
  background-color: var(--inputBackground);
  overflow: auto;
  height: 100%
}

.message {
  color: var(--neutral);
  padding: 20px;
}

.editor {
  height: 100%;
}
