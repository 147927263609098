.container {
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 1.4rem;
  max-height: calc(65vh);
  transition: opacity 100ms;
}

.container.isLoading,
.container.isPending {
  opacity: 0.3;
}