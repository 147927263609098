.container {
  composes: container from './FormRow.css';
  display: block;
  position: relative;
}

.container.isShaded {
  padding: 25px 10px 15px;
  background-color: var(--rowShade);
  border: 1px solid var(--gridLines);
}

.container.isShaded.hasHint {
  margin-bottom: 50px
}

.container.hasLegend:not(:first-child) {
  margin-top: 40px;
}

.legend {
  composes: label from '../../styles/type.css';

  /* Set position: absolute to unify x-brower fieldset/legend rendering inconsistencies https://bugzilla.mozilla.org/show_bug.cgi?id=942341 */
  position: absolute;
  top: -18px;
  left: 0;
}

.container.isShaded .legend {
  padding: 0 3px;
  top: -7px;
  left: 7px;
}

.container.isShaded .legend span {
  position: relative;
  z-index: 1;
}

.container.isShaded .legend:before {
  content: '';
  background-color: var(--rowShade);
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: calc(50% - 1px);
  left: 0;
  z-index: 0;
}

.container.isShaded .hint {
  position: absolute;
  bottom: -20px;
}

.inlineLegend {
  composes: label from '../../styles/type.css';
  display: inline-block;
  margin: 0;
}

.field {
  display: block;
  margin: 0 0 10px 0;
}

.field:last-child,
.field:only-child {
  margin: 0;
}

.inlineField {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 15px;
}

.inlineField:last-child {
  margin-right: 0;
}

.label:first-child {
  display: block;
  margin-bottom: 5px;
}

.hint {
  composes: hint from './FormRow.css';
}

.inlineHint {
  composes: inlineHint from './FormRow.css';
}
