.container {
  width: 100%;
}

.hasBorder {
  border-bottom: 1px solid var(--rowBorder);
}

.container caption {
  font-size: 2rem;
  margin-bottom: 15px;
  text-align: left;
}

.container tbody:first-child {
  border-top: 1px solid var(--rowBorder);
}

.container thead {
  border-bottom: 1px solid var(--rowBorder);
}

.container th {
  padding: 15px 15px 5px;
  text-transform: uppercase;
  font-size: 11px;
  text-align: left;
}

.container td {
  vertical-align: middle;
  position: relative;
  z-index: auto;
}

.container td:not([class]) {
  padding: 15px;
}

.isStriped:not(.isEmpty) tbody tr:not(:only-child):nth-child(odd) {
  background-color: var(--rowShade);
}

.container tbody tr.active,
.container:not(.isEmpty) tbody tr:not(:only-child):hover {
  background-color: var(--rowHover);
}

.container tbody tr:hover td {
  z-index: 1;
}
