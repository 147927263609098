.container {
  display: grid;
  grid-template-columns: 70% 30%;
  height: 100vh;
}

.main {
  composes: contentCell from '../../styles/contentCell.css';
}

.main > section:not(:last-child) {
  border-bottom: 1px solid var(--gridLines);
  padding-bottom: 40px;
}

.main > section:not(:first-child) {
  padding-top: 40px;
}

.main h1 {
  margin-top: 0;
}

@media (--small-device) {
  .container {
    display: block;
  }
}
