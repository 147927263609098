.container {
  width: 100%;
  height: calc(100vh - var(--appPaddingTop));
  display: grid;
  grid-template-columns: var(--sidebarWidth) 1fr;
  min-width: 0;
  min-height: 0;
  overflow: hidden;
}

.tooltip {
  composes: container from '../core/Link.css';
  font-weight: 400;
  text-transform: none;
}

:global(.isDemoMode) .container {
  height: calc(100vh - calc(var(--appPaddingTop) + var(--demoBannerHeight)));
}

.main {
  padding: 10px 30px 60px;
  overflow: auto;
}

.section:not(:last-of-type) {
  position: relative;
  padding-bottom: 90px;
}

.section:not(:last-of-type):after {
  content: '';
  position: absolute;
  left: -15px;
  bottom: 30px;
  background-color: var(--neutral);
  height: 1px;
  width: calc(100% + 50px);
}

.muted {
  color: var(--neutral);
  padding: 0 5px;
}

.iconCircle {
  position: relative;
  top: -2px;
  margin: 0 3px;
  width: 13px;
  height: 13px;
  cursor: pointer;
  fill: var(--accent);
}

.iconError,
.iconCheckmark {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.iconCheckmark {
  position: relative;
  top: -1px;
  fill: var(--accent);
}

.iconRemove {
  composes: iconRemove from '../resourceEditor/ListField.css';
}

.iconRemoveDisabled {
  composes: iconRemoveDisabled from '../resourceEditor/ListField.css';
}

@media (--x-large-device) {
  .main {
    padding-right: 10%;
  }
}
