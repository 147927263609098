.container {
  position: relative;
  background-color: var(--rowShade);
}

.content {
  display: grid;
  height: 100%;
}

.content > p {
  padding: 43px 10px 30px;
  margin: 0;
  text-align: center;
}

.iconQuestion {
  position: relative;
  top: -1px;
  margin: 0 6px;
  width: 13px;
  height: 13px;
  cursor: pointer;
  fill: var(--accent);
}

.diff {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  position: absolute;
  left: calc(100% - 20px);
  top: 41px;
  width: 41px;
  z-index: 1;
}

.diff:before,
.diff:after {
  content: '';
  height: 1px;
  width: 100%;
  background-color: var(--neutral);
}

.diffIcon,
.checkmarkIcon {
  fill: var(--neutral);
  margin: 0 1px;
  height: auto;
}

.diffIcon {
  cursor: pointer;
  width: 18px;
}

.diff:hover .diffIcon {
  fill: var(--accent);
}

.checkmarkIcon {
  width: 19px;
}
