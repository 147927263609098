.container {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0 20px;
  background-color: var(--navBar);
  display: grid;
  grid-template-columns: auto 1fr auto auto auto;
  align-items: center;
  height: var(--headerHeight);
  position: fixed;
  z-index: 2;
  box-shadow: 0px 1px 4px var(--borderShadow);
}

:global(.isDemoMode) .container {
  top: var(--demoBannerHeight);
}

.isImpersonating {
  background-color: var(--navBarImpersonating);
  border: none;
}

.logoLink {
  z-index: 1;
  margin-right: 40px;
  padding: 2px 3px;
}

.docsLink {
  justify-self: end;
  margin-left: 20px;
}

@media (prefers-color-scheme: dark) {
  .container.isAuthenticated {
    box-shadow: none;
  }

  .container.isAuthenticated:before {
    content: '';
    background-color: var(--navBarSelected);
    position: absolute;
    left: 0;
    top: calc(100% - 15px);
    width: 210px;
    height: 14px;
    box-shadow: -7px 3px 4px -1px var(--borderShadow);
    z-index: -1;
  }

  .container.isAuthenticated:after {
    content: '';
    background-color: var(--navBarSelected);
    position: absolute;
    left: 580px;
    top: calc(100% - 15px);
    width: calc(100% - 582px);
    height: 14px;
    box-shadow: 4px 3px 4px -1px var(--borderShadow);
    z-index: -1;
  }
}

@media (--small-device) {
  .container:before,
  .container:after {
    display: none;
  }
}