.container {
  align-items: center;
  align-self: center;
  position: relative;
}

.item {
  grid-column: 2;
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
}

.item > div:first-child {
  margin-right: 5px;
}

.item > div:last-child {
  justify-self: start;
  text-align: left;
}

.empty:hover {
  border: 1px solid var(--sidebarItem);
}

.item p {
  color: var(--neutral);
  margin: 0 0 3px;
}

.item p:last-child {
  margin: 0;
}

.icon {
  width: 25px;
  height: auto;
}

.icon.ok {
  fill: var(--accent);
}

.icon.neutral {
  fill: var(--neutral);
}

.iconError {
  fill: var(--error);
}

.iconSpinner {
  fill: var(--neutral);
}

.status {
  white-space: nowrap;
}

.version {
  border: 1px solid var(--gridLines);
  border-radius: 2px;
  background-color: var(--canvas);
  padding: 1px 3px;
  margin-left: 5px;
}

/* Fixes inconsistent line spacing in FF */
@-moz-document url-prefix() {
  .version {
    padding-top: 2px;
    padding-bottom: 0px;
  }
}

.timestamp {
  composes: timestamp from '../../styles/type.css';
}

.iconExpand {
  justify-self: center;
  fill: white;
}

.iconExpandRotated {
  composes: iconExpand;
  transform: rotate(90deg);
}

.isSelected .version {
  background-color: var(--versionBackground);
  border-color: var(--versionBackground);
}

.isSelected .version:not(a) {
  color: var(--neutral);
}

.isSelected .item p {
  color: var(--nodeTextHighlight);
}
