.container {
  display: grid;
  grid-template-columns: max-content max-content max-content auto;
  align-items: center;
  align-content: center;
  position: relative;
  margin-left: -10px;
}

.isLoggedOut {
  grid-template-columns: auto;
}

.navLink {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  padding-right: 2px;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: bold;
  position: relative;
  height: 100%;
  line-height: 1;
}

.navLink:only-child {
  margin-right: 0;
}

.navLink,
.navLink:visited {
  color: var(--neutral);
}

.navLink:hover {
  color: var(--accent);
}

.navLink > span {
  display: block;
  position: relative;
  width: 100%;
  padding: 10px 10px 10px 0;
}

.activeNavLink,
.activeNavLink:hover,
.activeNavLink:visited {
  color: var(--accent);
}

.activeNavLink:before {
  content: '';
  display: block;
  width: 100%;
  height: 52px;
  position: absolute;
  left: 0;
  top: -11px;
  background-color: var(--navBarSelected);
  box-shadow: 0px 4px 4px 0 var(--borderShadow) inset;
}

.icon {
  height: auto;
  margin-right: 2px;
  margin-left: 10px;
  z-index: 1;
}

.iconLayers {
  width: 19px;
}

.iconGlobe {
  width: 20px;
}

.iconSend {
  width: 18px;
  transform: rotate(-45deg) translateY(1px) translateX(3px);
}

@media (prefers-color-scheme: dark) {
  .navLink:not(.docsLink):after {
    content: '';
    position: absolute;
    width: calc(100% + 4px);
    background-color: var(--navBarSelected);
    position: absolute;
    left: -4px;
    bottom: -7px;
    height: 6px;
    z-index: -1;
    box-shadow: 3px 3px 4px -1px var(--borderShadow);
  }

  .activeNavLink:after {
    display: none;
  }
}

@media (--small-device) {
  .container:not(.isLoggedOut) {
    grid-template-columns: auto auto auto;
    position: absolute;
    background-color: var(--navBarSelected);
    top: 100%;
    left: 10px;
    width: 100%;
    width: 100%;
    padding: 5px 0;
    box-shadow: 0px 5px 7px -4px var(--borderShadow);
    border: 1px solid var(--gridLines);
    border-width: 1px 0;
  }

  .container:not(.isLoggedOut) .docsLink {
    position: absolute;
    top: -46px;
    right: 67px;
  }

  .navLink {
    justify-content: center;
  }

  .navLink:before,
  .navLink:after,
  .activeNavLink:before,
  .activeNavLink:after {
    display: none;
  }
}