.container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.scrollArea {
  width: 100%;
  height: 100%;
}

.svg {
  user-select: none;
}

.canvas {
  fill: var(--canvas);
}

.readOnly {
  fill: var(--canvas);
}

.grid {
  fill: none;
  shape-rendering: crispEdges;
  stroke: var(--gridLines);
}