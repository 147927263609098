.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.tooltip {
  composes: container from '../core/Link.css';
}

@media (--small-device) { 
  .container {
    display: block;
  }
}