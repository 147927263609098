.inactiveDot {
  fill: var(--neutral);
}

.errorDot {
  fill: var(--error);
}

.warningDot {
  fill: var(--warning);
}

.okDot {
  fill: var(--success);
}
