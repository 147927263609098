.container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  overflow-x: hidden;
}

.content {
  background-color: var(--secondary);
  color: var(--textLight);
  padding: 30px;
  min-width: var(--bpSmall);
  max-width: var(--bpMedium);
  margin: auto;
}

@media (--small-device) {
  .container {
    align-items: flex-start;
    padding: 30px 20px;
  }

  .content {
    min-width: 100%;
    height: 100%;
    padding: 30px 10px;
    margin: 0 auto;
  }
}
