.container {
  cursor: pointer;
  color: var(--link);
  border: 2px dashed var(--rowBorder);
  padding: 20px;
  margin-bottom: 40px;
  text-align: center;
  width: 100%;
}

.container:hover {
  color: var(--linkHover);
}

.container > span {
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
  justify-content: center;
}

.iconPlus {
  margin-right: 3px;
  width: 25px;
}