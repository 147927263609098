.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}

.container.hasHeader.hasSearch.hasButton {
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 20px;
}

.container > button {
  justify-self: end;
  width: auto;
  position: relative;
}