.container,
.container:visited {
  cursor: pointer;
  color: var(--link);
  text-decoration: none;
  padding: 0;
  white-space: nowrap;
}

button.container {
  border: none;
  text-align: unset;
}

.container:hover,
.container:focus {
  color: var(--linkHover);
}

.iconNewWindow {
  margin-left: 2px;
  width: 12px;
  height: auto;
}