.container {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 22px;
  width: 145px;
}

.container > button {
  position: fixed;
  width: inherit;
}

@media (--x-small-device) {
  .container {
    right: 50%;
    transform: translateX(50%);
  }
}