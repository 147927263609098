.container {}

.header {
  margin-bottom: 20px;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.item {
}

.item:not(:last-child) {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--rowBorder);
}

.itemHeader {
  display: grid;
  grid-template-columns: max-content max-content auto;
  align-items: center;
  margin-bottom: 6px;
}

.author {
  composes: container from '../deployments/Timestamp.css';
  margin-left: 5px;
  color: var(--text);
}

.authorPre {
  color: var(--neutral);
}

.version {
  composes: version from '../core/GitLink.css';
  margin: 0 5px 0 0;
}

.itemContent {
}

.itemContent p {
  margin: 0;
}

.itemContent p:last-of-type:not(:last-child) {
  margin-bottom: 10px;
}

.message {
  color: var(--text);
}

.message:not(:only-child) {
  margin-bottom: 3px;
}

.switchLink {
  margin-right: 3px;
}

.iconDoubleArrow {
  width: 22px;
  height: auto;
  transition: transform 100ms;
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

.switchLink:hover .iconDoubleArrow {
  transform: scaleX(-1);
}

.label {
  display: inline-block;
  position: relative;
  font-size: 1.4rem;
  height: 19px;
  line-height: 19px;
  margin: 0 5px 0 0;
  padding: 0 1px 1px 4px;
  background: var(--rowBorder);
  border-radius: 2px 0 0 2px;
}

.label:after {
  content:'';
  display: block;
  position: absolute;
  top: 0;
  left: 100%;
  width: 0;
  height: 0;
  border-color: transparent transparent transparent var(--rowBorder);
  border-style: solid;
  border-width: 9.5px;
}
