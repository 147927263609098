.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--zIndexMax);
  display: flex;
  grid-template-rows: 20px 1fr 20px;
  grid-template-columns: 20px 1fr 20px;
  align-items: center;
  justify-items: center;
  background: var(--shade);
  overflow: auto;
}

.modal {
  position: relative;
  background: var(--modalBackground);
  border-radius: 2px;
  padding: 30px 50px 40px;
  box-shadow: 0px 4px 22px -4px var(--modalShadow);
  font-size: 1.5rem;
  width: 100%;
  max-width: var(--modalMaxWidth);
  min-width: var(--modalMinWidth);
  margin: auto;
}

.modal.isParent:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--shade);
}

.modal.isChild {
  position: absolute;
  left: 50%;
  top: 50%;
  box-shadow: none;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1;
  height: auto;
  width: 90%;
}

.modal h1 {
  margin-top: -10px;
  font-size: 2.5rem;
}

.modal p:not([class]) {
  color: var(--textMuted);
}

.address {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  grid-column-gap: 15px;
}

.list {
  padding-left: 20px;
}

.listItem {
  margin-top: 10px;
  font-size: 1.5rem;
}

.providerList {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

.providerListItem {
  margin-top: 10px;
  font-size: 1.5rem;
}

.iconError,
.iconCheckmark {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.iconCheckmark {
  fill: var(--accent);
}

.video {
  display: block;
  max-width: 500px;
  margin: 20px auto;
}

.noMargin {
  margin: 0;
}


:global(.StripeElement) {
  padding: 10px;
  border: 1px solid var(--inputBorder);
  background: var(--inputBackground);
}

.hasError :global(.StripeElement) {
  border-bottom: solid 1px var(--error);
}

@media (--medium-device) {
  .modal {
    min-width: 0;
    padding: 40px 20px 20px;
  }

  .close {
    right: 0;
    top: 0;
  }
}

.capitalize {
  text-transform: capitalize;
}
