.container {
  display: grid;
  grid-template-columns: max-content 2fr 1fr;
  position: relative;
}

.isHidden {
  composes: fadeOut from '../../styles/keyframes.css';
}

.meter {
  padding: 20px;
  width: 220px;
  height: 220px;
  position: relative;
  text-align: center;
}

.progressMeter {
  position: absolute;
  top: 18px;
  left: 18px;
  z-index: auto;
}

.progressMeter:after {
  content: '';
  border: 1px solid var(--gridLines);
  border-radius: calc(100% - 2px);
  width: calc(100% - 3px);
  position: absolute;
  padding-top: calc(100% - 6px);
  left: 1px;
  top: 2px;
  z-index: -1;
}

.progressCircle {
  color: var(--accent);
  stroke-linecap: round;
}

.caption {
  display: grid;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
  font-weight: bold;
  width: 100%;
}

.percent {
  display: block;
}

.icon {
  width: auto;
  position: relative;
  top: -1px;
}

.iconCheckmark {
  height: 32px;
  fill: var(--gridLines);
}

.aside {
  display: none;
}

.aside p {
  color: var(--neutral);
  font-size: 1.8rem;
}

.todoList {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-rows: auto auto auto auto;
  align-self: center;
  align-items: start;
  height: 100%;
}

.todoItem {
  display: grid;
  grid-template-columns: max-content max-content;
  align-self: center;
  align-items: center;
}

.todoItemHeader {
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
  margin: 0;
}

/* .todoItem completion states */

.todoItem.isComplete .iconCheckmark {
  fill: var(--accent);
}

.todoItem.isComplete .todoItemHeader {
  color: var(--neutral);
}

.todoItem.isDisabled .todoItemHeader {
  color: var(--gridLines);
}

@media (--x-large-device) { 
  .aside {
    display: block;
  }
}

@media (--small-device) { 
  .container {
    display: block;
  }

  .meter {
    margin: 0 auto;
  }

  .todoList {
    justify-content: center;
  }

  .todoItem:not(:last-child) {
    margin-bottom: 8px;
  }
}