.container {
  display: grid;
  padding: 20px;
  height: 100%;
}

.container.isSiblingRow {
  padding-top: 0;
}

.container:last-child {
  margin-bottom: 0;
}

.timestamp {
  text-transform: lowercase;
}

.content {
  display: grid;
  grid-template-rows: auto 1fr auto;
  padding: 20px;
  background: var(--inputBackground);
  border-radius: 2px;
  border: 1px solid var(--rowBorder);
}