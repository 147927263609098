.container {
  composes: container from './Button.css';
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  text-decoration: none;
  width: auto;
  padding: 5px 9px;
}

.isInline {
  margin: 0 10px;
}

.isDisabled {
  composes: isDisabled from './Button.css';
}

.text {
  padding: 0 5px;
}

.hasIcon {
  composes: hasIcon from './Button.css';
  display: grid;
  grid-template-columns: auto auto auto;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.hasIcon .text {
  margin-left: 2px;
}

.icon {
  fill: var(--buttonIconFill);
  max-height: 24px;
}

.primary {
  composes: primary from './Button.css';
}

.secondary {
  composes: secondary from './Button.css';
}

.outline {
  composes: outline from './Button.css';
}