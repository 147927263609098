.container {
}

.nav {
  display: grid;
  align-items: center;
  text-align: center;
  width: 50%;
}

.tab {
  cursor: pointer;
  padding: 15px 20px;
  text-transform: uppercase;
  white-space: nowrap;
  margin-right: 2px;
  border: none;
  border-radius: 2px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.tab:last-of-type {
  margin-right: none;
}

.tabActive {
  font-weight: bold;
}

.content {
}

.panel {
  opacity: 0;
  height: 0;
  position: relative;
  z-index: -1;
  pointer-events: none;
  overflow: visible;
}

.panelActive {
  opacity: 1;
  height: auto;
  padding: 10px 0;
  border-radius: 2px;
  border-top-left-radius: 0;
  z-index: auto;
  pointer-events: auto;
}

/* theme .light */

.light .tab {
  background-color: var(--contentLightMutedBackground);
  color: var(--contentLightMutedText);
}

.light .tabActive {
  background-color: var(--contentLightBackground);
  color: var(--contentLightText);
}

.light .panelActive {
  background-color: var(--contentLightBackground);
  color: var(--contentLightText);
}

/* theme .dark */

.dark .tab {
  background-color: var(--contentDarkMutedBackground);
  color: var(--contentDarkMutedText);
}

.dark .tabActive {
  background-color: var(--contentDarkBackground);
  color: var(--contentDarkText);
}

.dark .panelActive {
  background-color: var(--contentDarkBackground);
  color: var(--contentDarkText);
}