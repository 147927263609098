:root {
  /** PRIVATE COLORS **/
  /** -- use private color names within this schema only -- **/

  /** brand colors **/
  --coral: #ee847b;
  --teal: #81cbb5;
  --navy: #002935;
  --cream: #f6f3f0;

  /** non-brand colors **/
  /** -- define color name/value pair to use within this schema http://chir.ag/projects/name-that-color -- **/
  --blue-dianne: #274a58;
  --tiber: #082835;
  --pickled-bluewood: #273F4B;
  --cutty-sark: #5b7582;
  --elephant: #113543;
  --big-stone: #244350;
  --stormy: #112f3c;
  --jewel: #2f4e5a;
  --deep-sea: #1d404e;
  --firefly: #091d25;
  --bunker: #12181b;
  --monte-carlo: #84CDB6;
  --valencia: #c75451;
  --mountain-meadow: #20CC80;
  --tulip-tree: #f1c376;
  --alabaster: #FCFCFC;
  --hit-gray: #9FADB5;
  --regent-gray: #7d8f98;
  --spruce: #38464c;
  --fog: #8f9ba2;
  --tundora: #4a4a4a;
  --porcelain: #f3f5f6;
  --athens-gray: #e3e8eb;
  --concrete: #f2f2f2;
  --alto: #dedede;
  --silver: #CCCCCC;
  --silver-translucent: rgba(204, 204, 204, 0.5);
  --hoki: #658897;
  --black-pearl: #082029;
  --mountain-meadow: #12BD9B;
  --saffron: #F5C333;
  --rolling-stone: #7A868A;
  --gallery: #ececec;
  --white: #ffffff;
  --emperor: #555555;
  --oracle: #396779;
  --bermuda: #88DDC2;
  --overcast: #EBEBEB;
  --stratus: #E8EDF0;
  --cream: #f9f9f9;
  --dew: #EAFFFF;
  --mint: #f4f7f4;
  --rosy: #fff8f7;
  --smog: #f7f3ee;
  --sky: #59a8c5;
  --mist: #d7e3e8;
  --acapulco: #73b29e;
  --jagged-ice: #c9e9df;
  --cape-cod: #4a4f4d;
  --apple-blossom: #ad4946;
  --rose-fog: #ecc6c5;

  --alpha-gray-2: rgba(220,220,220, 0.2);
  --alpha-gray-5: rgba(220,220,220, 0.5);
  --alpha-black-3: rgba(0, 0, 0, 0.3);
  --alpha-black-6: rgba(0, 0, 0, 0.6);
  --alpha-blue-4: rgba(227, 232, 235, 0.4);
  --alpha-blue-8: rgba(227, 232, 235, 0.8);
  --alpha-blue-9: rgba(227, 232, 235, 0.9);
  /** END: PRIVATE COLORS **/


  /** PUBLIC COLORS **/
  /** -- use public color names in css module rules -- **/

  /** palette **/
  --primary: var(--tiber);
  --secondary: var(--pickled-bluewood);
  --accent: var(--monte-carlo);
  --accentLight: var(--jagged-ice);
  --accentShade: var(--acapulco);
  --accentShadeTint: var(--cape-cod);
  --neutral: var(--hit-gray);
  --inverse: var(--alabaster);
  --splash: var(--coral);

  --info: var(--sky);
  --infoMuted: var(--mist);
  --success: var(--accent);
  --successMuted: var(--mint);
  --warning: var(--tulip-tree);
  --warningMuted: var(--smog);
  --error: var(--valencia);
  --errorMuted: var(--rosy);
  --errorShade: var(--apple-blossom);
  --errorLight: var(--rose-fog);

  /** layout **/
  --text: var(--tundora);
  --textLight: var(--alabaster);
  --textMuted: var(--rolling-stone);
  --heading: var(--tiber);
  --body: var(--cream);

  --headerBarBorder: var(--teal);
  --navBar: var(--primary);
  --navBarSelected: var(--primary);
  --navBarImpersonating: var(--coral);
  --userNavText: var(--hit-gray);
  --userNavBold: var(--cutty-sark);

  /** editor **/
  --canvas: var(--porcelain);
  --canvasReadOnly: var(--athens-gray);
  --gridLines: var(--athens-gray);
  --node: var(--blue-dianne);
  --nodeHighlight: var(--accent);
  --nodeStroke: var(--blue-dianne);
  --nodeLabel: var(--athens-gray);
  --nodeText: var(--alabaster);
  --nodeTextHighlight: var(--alabaster);
  --customNode: var(--oracle);
  --nodeWell: var(--concrete);
  --port: var(--silver);
  --wire: var(--hoki);
  --shade: var(--alpha-grey-5);
  --versionBackground: var(--alpha-grey-2);
  --codeBlockBar: var(--overcast);
  --iconFill: var(--hit-gray);
  --spinnerFill: var(--hit-gray);

  --modalBackground: var(--alabaster);
  --notificationBackground: var(--porcelain);
  --notificationBorder: var(--cutty-sark);
  --notificationBorderError: var(--error);
  --notificationText: var(--text);
  --notificationIconFill: var(--neutral);

  /** box-shadow **/
  --panelShadow: var(--alpha-gray-2);
  --defaultContainerShadow: var(alpha-gray-5);
  --insetShadow: var(--alto);
  --shadedBox: var(--gallery);
  --modalShadow: var(--alpha-black-3);
  --borderShadow: var(--alpha-black-6);

  /** links **/
  --link: var(--accent);
  --linkHover: var(--accentShade);
  --linkIndicator: var(--coral);

  /** sidebar **/
  --sidebarItem: var(--blue-dianne);
  --sidebarItemBorderHover: var(--silver-translucent);
  --deploymentDetailsBackground: var(--black-pearl);
  --changeCreate: var(--mountain-meadow);
  --changeModify: var(--saffron);
  --changeDelete: var(--valencia);

  /** demo banner **/
  --demoBannerBackground: var(--dew);

  /** forms **/
  --inputText: var(--tundora);
  --inputBorder: var(--athens-gray);
  --inputBackground: var(--white);
  --disabledInputBackground: var(--gallery);
  --disabledInputText: var(--emperor);

  /** buttons **/
  --buttonPrimaryBackground: var(--accent);
  --buttonPrimaryHoverBackground: var(--accentShade);
  --buttonPrimaryLoadingBackground: var(--accentShade);
  --buttonPrimaryDisabledBackground: var(--accentLight);
  --buttonPrimaryDisabledText: var(--porcelain);

  --buttonWarningBackground: var(--error);
  --buttonWarningHoverBackground: var(--errorShade);
  --buttonWarningLoadingBackground: var(--errorShade);
  --buttonWarningDisabledBackground: var(--errorLight);

  --buttonTransparentBackground: rgba(0, 0, 0, 0.02);

  --buttonOutlineBorder: var(--athens-gray);
  --buttonOutlineDisabledText: var(--neutral);

  --buttonIconFill: var(--cream);

  /** tables **/
  --rowBorder: var(--athens-gray);
  --rowHover: var(--stratus);
  --rowShade: var(--porcelain);

  /** tooltips **/
  --popoverText: var(--hit-gray);
  --popoverBackground: var(--porcelain);

  /** tabs, codeblock **/
  --contentDarkBackground: var(--pickled-bluewood);
  --contentDarkMutedBackground: var(--athens-gray);
  --contentDarkHoverBackground: var(--tiber);
  --contentDarkText: var(--inverse);
  --contentDarkHoverText: var(--inverse);
  --contentDarkMutedText: var(--neutral);

  --contentLightBackground: var(--porcelain);
  --contentLightMutedBackground: var(--cream);
  --contentLightHoverBackground: var(--athens-gray);
  --contentLightText: var(--primary);
  --contentLightHoverText: var(--neutral);
  --contentLightMutedText: var(--neutral);

  /** END: PUBLIC COLORS **/

}

@media (prefers-color-scheme: dark) {
  :root {
    --inverse: var(--firefly);
    --alpha-gray-5: rgba(96, 118, 128, 0.7);
    --alpha-blue-8: rgba(17, 53, 67, 0.8);
    --alpha-blue-9: rgba(17, 53, 67, 0.9);

    /** layout **/
    --text: var(--alto);
    --textMuted: var(--hit-gray);
    --heading: var(--athens-gray);
    --body: var(--tiber);

    --userNavText: var(--inverse);
    --userNavBold: var(--inverse);

    /** buttons **/
    --buttonPrimaryHoverBackground: var(--accentShade);
    --buttonPrimaryLoadingBackground: var(--accentShade);
    --buttonPrimaryDisabledBackground: var(--accentShadeTint);
    --buttonPrimaryDisabledText: var(--rolling-stone);

    --buttonIconFill: var(--tiber);

    --buttonTransparentBackground: rgba(0, 0, 0, 0.09);

    --buttonOutlineBorder: var(--deepSea);

    /** editor **/
    --canvas: var(--black-pearl);
    --canvasReadOnly: var(--black-pearl);
    --gridLines: var(--deep-sea);
    --node: var(--firefly);
    --nodeLabel: var(--hit-gray);
    --nodeText: var(--athens-gray);
    --nodeTextHighlight: var(--inverse);
    --nodeStroke: var(--rolling-stone);
    --customNode: var(--jewel);
    --nodeWell: var(--black-pearl);
    --port: var(--neutral);
    --wire: var(--neutral);
    --shade: var(--alpha-gray-5);
    --versionBackground: var(--alpha-blue-9);
    --codeBlockBar: var(--overcast);
    --spinnerFill: var(--fog);

    --modalBackground: var(--tiber);

    /** box-shadow **/
    --insetShadow: var(--bunker);

    /** forms **/
    --inputText: var(--alto);
    --inputBorder: var(--jewel);
    --inputBackground: var(--firefly);
    --disabledInputBackground: var(--gallery);
    --disabledInputText: var(--emperor);

    /** tables **/
    --rowBorder: var(--jewel);
    --rowHover: var(--big-stone);
    --rowShade: var(--stormy);

    /** tooltips **/
    --popoverText: var(--inverse);
    --popoverBackground: var(--regent-gray);

    /** tabs, codeblock **/
    --contentDarkBackground: var(--neutral);
    --contentDarkMutedBackground: var(--cutty-sark);
    --contentDarkHoverBackground: var(--primary);
    --contentDarkText: var(--inverse);
    --contentDarkHoverText: var(--text);
    --contentDarkMutedText: var(--hit-gray);

    --contentLightBackground: var(--pickled-bluewood);
    --contentLightMutedBackground: var(--cutty-sark);
    --contentLightHoverBackground: var(--neutral);
    --contentLightText: var(--neutral);
    --contentLightHoverText: var(--tiber);
    --contentLightMutedText: var(--athens-gray);
  }
}
