.container {
  overflow: auto;
  padding: 20px 20% 60px 30px;
}

.header {
  margin-bottom: 0;
}

.container form:first-of-type {
  padding-top: 0;
}

.container form:not(:last-of-type) {
  border-bottom: 1px solid var(--gridLines);
  padding-bottom: 50px;
}

.container form:not(:last-child) {
  margin-bottom: 30px;
}