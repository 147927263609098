.container {
  margin-top: -2px;
  margin-left: 0;
  border-radius: 2px;
  color: var(--text);
  background-color: var(--inputBackground);
  border: 1px solid var(--inputBorder);
  box-shadow: none;
  outline: none;
}

.container label,
.container label .outlined .shrink {
  font-size: 1.5rem;
  color: var(--text);
}

.container button {
  color: var(--text);
}

.container :global(.MuiOutlinedInput-notchedOutline),
.container :global(.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline) {
  top: 0;
  border: none;
}

.container :global(.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline) {
  outline: 1px dotted var(--neutral);
}

.container :global(.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]) {
  padding: 0;
  box-shadow: none;
}

.input {
  padding: 2px 0;
}

.inputRoot {
  font-size: 1.5rem;
  color: var(--text);
  box-shadow: none;
  outline: none;
  padding: 5px 0;
}

:global(.isTabbing) .inputFocused:focus {
  outline: none;
}

.paper {
  color: var(--text);
  background-color: var(--inputBackground);
  border-radius: 0;
  padding: 0;
  margin: 0;
  box-shadow: none;
  border: 1px solid transparent;
}

.paper li {
  font-size: 1.2rem;
}

.paper ul li.active,
.paper ul li:not(:only-child):hover {
  background-color: var(--rowHover);
}

.popper {
  border: 1px solid var(--inputBorder);
  background-color: var(--inputBackground);
}

.popper[x-placement='top'] {
  border-bottom: none;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  box-shadow: 0px -10px 20px -10px var(--modalShadow);
  margin-bottom: -3px;
}

.popper[x-placement='bottom'] {
  border-top: none;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  box-shadow: 0px 11px 20px -10px var(--modalShadow);
  margin-top: -2px;
}

.option:hover {
  background: var(--rowHover);
}

.noOptions {
  color: var(--text);
  font-size: 1.2rem;
  padding: 6px;
}

.tag {
  color: var(--text);
  background-color: var(--rowShade);
  border: 1px solid var(--rowShade);
  font-size: 1.2rem;
}

.tag svg {
  color: var(--text);
}

.tag svg:hover {
  color: var(--accent);
}

.listbox {
  padding: 0;
}

.colorPrimary {
  color: var(--neutral);
}

.colorPrimary.isSelected
.colorPrimary.focused {
  color: var(--accent);
}

.colorPrimary.isDisabled {
  color: var(--disabledInputText);
}

.endAdornment {
  background: url('../../images/arrows-up-down-dark.svg') calc(100% - 10px) / 7px no-repeat;
  display: block;
  width: 25px;
  height: 35px;
  top: calc(50% - 16px);
}

.endAdornment :global(.MuiAutocomplete-popupIndicator) {
  opacity: 0;
  right: -1px;
  width: 20px;
  height: 20px;
  top: -12px;
}

.endAdornment :global(.MuiAutocomplete-clearIndicator) {
  margin-right: 0;
  right: 15px;
  top: calc(50% - 10px);
}

@media (prefers-color-scheme: dark) {
  .endAdornment {
    background-image: url('../../images/arrows-up-down-light.svg');
  }
}