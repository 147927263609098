.container {
  margin: 10px 0;
}

.container.hasIcon {
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
}

.container.alignCenter {
  text-align: center;
}

.container p {
  margin: 0;
}

.icon {
  width: 25px;
  height: auto;
  margin-right: 10px;
}