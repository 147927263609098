.container {
  width: 100vw;
  height: 100vh;
  padding-top: var(--headerHeight);
  overflow: hidden;
}

.content {
  height: 100%;
  width: 100%;
}

:global(.isChromeless) {
  padding-top: 0;
}

:global(.isChromeless) .content {
  padding-top: 0;
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  left: 0;
  top: 0;
}
