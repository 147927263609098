.container {
  display: block;
  width: 100%;
  border-radius: 2px;
  background-color: var(--inputBackground);
  border: 1px solid var(--inputBorder);
  padding: 8px;
  color: var(--inputText);
}

.readOnly {
  color: var(--neutral);
}

.container[type="radio"],
.container[type="checkbox"] {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.hasError {
  border: 1px solid var(--error);
}
