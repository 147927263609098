.title {
  composes: headingLarge from '../../styles/type.css';
  letter-spacing: 1.5px;
  font-weight: normal;
  color: var(--heading);
}

.subtitle {
  font-size: 1.6rem;
  font-weight: bold;
  margin: 0 0 2px;
  color: var(--neutral);
}

.subtitle > a {
  padding-left: 8px;
  font-weight: normal;
}

.errorList {
  padding: 0 0 0 15px;
}