.container {
  min-height: 50px;
  padding: 10px;
  background-color: var(--deploymentDetailsBackground);
  position: relative;
}

.changeContainer {
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  color: var(--textMuted);
  font-size: 12px;
  line-height: 1.5;
}

.changeAction {
  font-weight: bold;
}

.changeActionCreate {
  composes: changeAction;
  color: var(--changeCreate);
}

.changeActionModify {
  composes: changeAction;
  color: var(--changeModify);
}

.changeActionDelete {
  composes: changeAction;
  color: var(--changeDelete);
}

.iconCircle {
  width: 13px;
  height: 13px;
  fill: var(--neutral);
}
