.container {
  background-color: transparent;
  box-shadow: none;
  padding: 8px;
}

.container:before {
  display: none;
}

.summary {
  padding: 0 10px;
}

.summary.isFocused {
  background-color: transparent;
}

.summary.isOpen {
  min-height: 0;
}

.content {
  display: block;
  padding: 0 30px 10px 20px;
}

.icon {
  cursor: pointer;
  fill: var(--accent);
  font-size: 26px;
}