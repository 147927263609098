.wire {
  stroke: var(--wire);
  stroke-width: 3px;
  cursor: crosshair;
  fill: none;
}

.background {
  fill: none;
  stroke: transparent;
  stroke-width: 20px;
}

.isReference .wire {
  stroke-dasharray: 6 1;
}

.isSelected .wire {
  stroke: var(--nodeHighlight);
}