.contentCell {
  padding: 50px 50px var(--appPaddingBottom);
}

@media (--medium-device) {
  .contentCell {
    padding: 30px 20px 60px;
  }
}

@media (--small-device) {
  .contentCell {
    padding: 30px 10px 60px;
  }
}