.container:first-child {
  margin-right: 10px;
}

.iconChecked {
  margin: -1px 0 0 4px;
  fill: var(--buttonIconFill);
}

.iconUnchecked {
  margin: -1px 0 0 3px;
  fill: var(--buttonIconFill);
}