.container {}

.message {
  background-color: var(--rowShade);
  padding: 20px;
  margin: 10px 0;
}

.callout {
  margin-top: 50px;
}

.buttonGroup {
  display: grid;
  grid-template-columns: auto auto auto;
  margin-bottom: 20px;
}

.button:not(:last-child) {
  margin-right: 20px;
}
