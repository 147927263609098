.container {
  cursor: pointer;
  display: grid;
  position: absolute;
  top: 0;
  width: 50px;
  height: 100%;
  text-align: center;
  align-content: center;
}

.container:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: calc(100%);
}

.container.selector {
  display: block;
  position: relative;
  width: 60px;
  padding: 0 2px;
}

.container.opaque {
  background-color: var(--inputBackground);
  border: 1px solid var(--inputBorder);
  border-radius: 2px;
  max-height: var(--inputMaxHeight);
}

.container.adjoined {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.container.right {
  right: 0;
}

.container.left {
  left: 0;
}

.container.auto {
  right: auto;
  left: auto;
}

/* /////// */

.menu {
  opacity: 0;
  width: 0;
  height: 0;
  background-color: var(--inputBackground);
  box-shadow: 0px 5px 17px 2px var(--modalShadow);
  border: 1px solid var(--inputBorder);
  border-radius: 2px;
  position: absolute;
  top: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: -1;
  overflow: hidden;
}

.menu:before,
.menu:after {
  content: '';
  position: absolute;
}

.menu:before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--inputBorder);
  top: -10px;
  left: 7px;
}

.menu:after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid var(--inputBackground);
  top: -8px;
  left: 8px;
}

.right .menu {
  left: auto;
  right: 0;
}

.right .menu:before {
  left: auto;
  right: 5px;
}

.right .menu:after {
  left: auto;
  right: 6px;
}

.right.selector .menu:before {
  right: 1px;
}

.right.selector .menu:after {
  right: 2px;
}

.menu.active {
  opacity: 1;
  z-index: 2;
  width: auto;
  height: auto;
  padding: 5px 0;
  overflow: visible;
}

/* /////// */

.selector {
  display: grid;
  height: 100%;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: var(--inputText);
}

.opaque .selector {
  color: var(--neutral);
}

.selector:hover,
.selector.active {
  color: var(--link);
}

/* /////// */

.iconDots {
  width: 20px;
  height: 20px;
  justify-self: center;
  align-self: center;
}

.iconDots:hover,
.iconDots.active {
  fill: var(--link);
}

.iconChevron {
  width: 15px;
  height: auto;
  justify-self: end;
}

.iconChevron:hover,
.iconChevron.active {
  fill: var(--link);
}

.iconChevron.active {
  fill: var(--link);
  transform: rotate(180deg);
}