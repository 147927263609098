.container {
  display: block;
  margin: 0 0 30px 0;
  padding: 0;
  border: none;
  position: relative;
}

.container:only-child {
  margin: 0;
}

.hasError {
  border: 1px solid var(--error);
}

.isHidden {
  display: none;
}

.label {
  composes: label from '../../styles/type.css';
  display: inline-block;
  color: var(--text);
}

.hint {
  display: block;
  text-transform: none;
  font-weight: normal;
  font-size: 1.1rem;
  margin: 5px 0 0;
  color: var(--text);
}

.inlineHint {
  composes: hint;
  display: inline;
  padding-left: 5px;
}
