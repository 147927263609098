:root {
  --borderRadius: 2px;
}

.container {
}

.content {
  background-color: var(--inputBackground);
  padding: 0 10px 10px;
  position: relative;
  display: grid;
  min-width: 200px;
}

.userName {
  color: var(--text);
  border-top-left-radius: var(--borderRadius);
  border-top-right-radius: var(--borderRadius);
  position: relative;
  bottom: 0;
  left: -10px;
  width: calc(100% + 20px);
  padding: 15px 10px 15px 13px;
}

.navLink,
.navLink:visited {
  color: var(--link);
}

.navLink {
  cursor: pointer;
  font-size: 0.8em;
  font-weight: bold;
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--link);
  padding: 5px 10px;
  margin: 0 -10px;
}

.navLink:hover {
  background-color: var(--rowHover);
}

.divider {
  border: none;
  border-bottom: 1px solid var(--inputBorder);
  position: relative;
  margin: 0 0 14px -10px;
  width: calc(100% + 20px);
}

.iconPower {
  width: 18px;
  margin-right: 2px;
}
