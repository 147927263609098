.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.search {
  margin: 0 0 10px 0;
}

.title {
  font-size: 26px;
  letter-spacing: 1.5px;
  line-height: 1em;
  color: var(--heading);
}

@media (--medium-device) {
  .container {
    grid-template-columns: 1fr;
  }
}