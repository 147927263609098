.container {
  border-radius: 2px;
  border: 1px solid var(--inputBorder);
  background-color: var(--inputBackground);
  margin-top: 10px;
  position: relative;
}

/* Caret pointing up */
.container:before,
.container:after {
  content: '';
  position: absolute;
}

.container:before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--inputBorder);
  top: -10px;
  right: 10px;
}

.container:after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid var(--inputBackground);
  top: -8px;
  right: 11px;
}

.container.isGroup:before,
.container.isGroup:after {
  left: auto;
  transform: none;
}

.container.isGroup:before {
  right: 9px;
}

.container.isGroup:after {
  right: 10px;
}
/***/

.list {
  padding: 5px 0;
}

.item {
  composes: container from './ContextMenuItem.css';
  padding: 7px 15px;
}

.item:hover {
  cursor: pointer;
}