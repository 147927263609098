:root {
  --headerHeight: 50px;
  --appPaddingTop: var(--headerHeight);
  --appPaddingBottom: 75px;
  --sidebarWidth: 200px;
  --sidebarPadding: 20px;
  --subNavWidth: 160px;
  --subHeaderHeight: 90px;
  --modalMaxWidth: 850px;
  --modalMinWidth: 700px;
  --inputMaxHeight: 38px;
  --demoBannerHeight: 50px;
  --fixedToolTopPosition: 155px;
  --fixedToolRightPosition: 20px;
  --zIndexMax: 1301;

  --bpXsmall: 480px;
  --bpSmall: 660px;
  --bpMedium: 900px;
  --bpLarge: 1200px;
  --bpXlarge: 1400px;
}

/*
CSS vars in media queries are not natively supported. (https://www.w3.org/TR/css-variables-1/#using-variables)
The postcss-custom-media plugin is used to achieve media queries as variables.
However, postcss-custom-media doesn't work with vars as dimension values so, value repetition is necessary.
*/

@custom-media --xx-small-device (max-width: 400px);
@custom-media --x-small-device (max-width: 480px);
@custom-media --small-device (max-width: 660px);
@custom-media --medium-device (max-width: 1000px);
@custom-media --large-device (min-width: 1001px);
@custom-media --x-large-device (min-width: 1441px);

@media (--small-device) {
  :root {
    --appPaddingTop: 92px;
    --sidebarWidth: 175px;
    --fixedToolTopPosition: 197px;
  }
}
