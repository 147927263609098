.container {
  font-size: 1.1rem;
  align-items: center;
  position: relative;
}

.container.info {
  background-color: var(--infoMuted);
}

.container.success {
  background-color: var(--successMuted);
}

.container.warning {
  background-color: var(--warningMuted);
}

.container.error {
  background-color: var(--errorMuted);
}

.container:not(.isVisible) {
  background-color: var(--popoverBackground);
}

.content {
  width: 350px;
  overflow: hidden;
}

.isOpen .content,
.isClosed .content {
  padding-right: 15px;
}

.content p {
  white-space: nowrap;
  margin: 0;
}

.content p:not(:first-of-type) {
  display: none; /* Hide notification description */
}

.isClosed .content p {
  overflow: hidden;
  text-overflow: ellipsis;
}

.isOpen .content p {
  overflow: visible;
  white-space: break-spaces;
}

.toggleLink {
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
}

.isOpen .action {
  align-self: start;
  top: 3px;
  position: relative;
}

.isOpen .icon {
  align-self: start
}

.info .icon svg {
  fill: var(--info);
}

.success .icon svg {
  fill: var(--success);
}

.warning .icon svg {
  fill: var(--warning);
}

.error .icon svg {
  fill: var(--error);
}

.timer {
  opacity: 0;
  background-color: transparent;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: auto;
  width: 100%;
  height: 3px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.isVisible .timer {
  opacity: 0.6;
}

.timerBar {
  background-color: var(--neutral);
  transition-duration: 500ms;
}

.info .timerBar {
  background-color: var(--info);
}

.success .timerBar {
  background-color: var(--success);
}

.warning .timerBar {
  background-color: var(--warning);
}

.error .timerBar {
  background-color: var(--error);
}

@media (prefers-color-scheme: light) {
  .container:not(.isVisible) {
    border: 1px solid var(--rowBorder);
  }
}
