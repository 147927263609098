.container {
  background-color: var(--body);
  display: grid;
  grid-template-rows: auto 1fr;
  position: relative;
  width: 100%;
  height: 100vh;
}

.main {
  display: grid;
  overflow: hidden;
  position: relative;
  border: 1px solid var(--gridLines);
  height: 100%;
}

.header {
  display: grid;
  grid-template-columns: 215px auto;
  align-items: center;
  align-content: center;
  position: relative;
  padding: 21px 0 0 21px;
}

.header > * {
  margin: 0;
}

.cta {
  position: absolute;
  right: 12px;
  bottom: 8px;
  display: grid;
  grid-template-rows: auto auto;
  align-items: center;
  justify-items: center;
  min-width: 160px;
}

/*
Hack to remove the 'new-window' icon from the Link in this instance.
Since the icon comes from Link and is a separate element...
...it doesn't layout correctly with `grid-template-rows: auto auto` (without adding more complicated / less x-compatible grid properties).
*/
.cta svg[name='new-window'] {
  display: none;
}

.label {
  composes: label from '../../styles/type.css';
}

.title {
  text-transform: capitalize;
  color: var(--neutral);
}

.nav {
  display: grid;
  grid-template-columns: auto auto;
}

.navLink,
.navLink:visited {
  color: var(--neutral);
}

.navLink {
  display: grid;
  position: relative;
  padding: 10px;
  grid-template-rows: auto auto;
  grid-template-columns: none;
  justify-items: center;
  text-transform: uppercase;
  font-size: 1.12rem;
  text-decoration: none;
  border: 1px solid transparent;
  border-bottom: none;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}

.activeNavLink {
  box-shadow: 0px 0px 5px 1px var(--alpha-black-3);
  background-color: var(--rowShade);
  border-color: var(--gridLines);
}

.activeNavLink,
.activeNavLink:visited,
.navLink:hover {
  color: var(--accent);
}

.iconLogo {
  width: 30px;
  height: auto;
  margin-bottom: 5px;
}

.iconEditor,
.iconGrid {
  margin: 0 0 3px;
}

.iconEditor {
  width: 20px;
}

.iconGrid {
  width: 19px;
}

/** .container.isStandalone **/

.container.isStandalone {
  display: block;
}

.container.isStandalone .header {
  position: absolute;
  bottom: 8px;
  right: 8px;
  height: 30px;
  width: 175px;
  z-index: 1;
  padding: 0;
}

.container.isStandalone .cta {
  grid-template-rows: none;
  grid-template-columns: auto auto;
  right: 0;
  bottom: 0;
}

.container.isStandalone .iconLogo {
  width: 25px;
  margin-bottom: 0;
  margin-right: 5px;
}

.container.isStandalone .nav {
  display: none;
}

.container.isStandalone .main {
  border: none;
}

/******/

@media (--x-small-device) {
  .header {
    grid-template-columns: 179px auto;
    padding-left: 0;
  }

  .container.isStandalone .header {
    bottom: 7px;
    right: 8px;
  }

  .container.isStandalone.isDesignMode .header {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }

  .cta {
    right: 0;
  }

  .container.isStandalone .cta {
    right: auto;
    bottom: auto;
  }

  .container.isStandalone .iconLogo {
    align-self: end;
  }

  .navLink {
    padding: 7px;
  }
}

@media (--xx-small-device) {
  .header {
    padding-left: 0;
  }

  .container.isStandalone .header {
    width: 150px;
  }

  .cta {
    min-width: 150px;
    font-size: 1.3rem;
  }

  .container.isStandalone .iconLogo {
    width: 15px;
    margin-right: 0;
  }
}