:root {
  --paddingLeft: 20px;
}

.container {
  padding: 10px;
  position: relative;
}

.container:not(:last-child) {
  border-bottom: 1px solid var(--rowBorder);
}

.header {
  display: grid;
  grid-template-columns: auto auto 1fr max-content;
  align-items: center;
  height: 42px;
}

.title {
  composes: label from '../../styles/type.css';
  margin: 0 10px 0 0;
}

.iconLevel {
  margin: 0 6px 0 0;
}

.content {
  color: var(--neutral);
  margin-bottom: 10px;
  padding: 0 30px 0 var(--paddingLeft);
}

.content p {
  margin: 20px 0 0;
}

.footer {
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
  padding-left: var(--paddingLeft);
}

.footer.isMultiColumn {
  grid-template-columns: auto auto max-content;
}

.chatButton {
  margin-left: 10px;
}