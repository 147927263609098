.container {
  box-shadow: 0px 0px 20px 0px var(--insetShadow) inset;
  background-color: var(--canvas);
  overflow-y: auto;
  overflow-x: auto;
  position: relative;
}

.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  padding: 30px 0;
}

.navLink,
.navLink:visited {
  color: var(--neutral);
}

.navLink:hover,
.navLink:hover:visited {
  color: var(--accent);
}

.navLink {
  composes: smallCaps from '../../styles/type.css';
  text-decoration: none;
  font-weight: normal;
  border-top: 1px solid var(--gridLines);
  padding: 15px var(--sidebarPadding);
  margin: 1px 0 0;
  position: relative;
}

.navLink:last-of-type {
  border-bottom: 1px solid var(--gridLines);
}

.activeNavLink,
.activeNavLink:visited {
  color: var(--accent);
  background-color: var(--body);
  font-weight: bold;
  box-shadow: -4px 0 15px -6px var(--insetShadow) inset
}

.iconChevron {
  width: 20px;
  fill: var(--neutral);
  position: absolute;
  right: 15px;
  top: calc(50% - 1px);
  transform: translateY(-50%);
}

.navLink:hover .iconChevron,
.activeNavLink .iconChevron {
  fill: var(--accent);
}

.licensesLink {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}