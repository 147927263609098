.container {
}

.eventHeader {
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
  color: var(--neutral);
  font-weight: normal;
  margin-bottom: 3px;
}

.event {
  display: grid;
  grid-template-columns: 1fr max-content;
  padding: 5px 5px 5px 0;
  color: var(--text);
}

.timestamp {
  composes: timestamp from '../../styles/type.css';
  color: var(--neutral);
}

.tooltip {
  composes: container from '../core/Link.css';
}

.error {
  color: var(--error);
}