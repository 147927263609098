.container {
  display: grid;
  grid-template-rows: var(--subHeaderHeight) 1fr;
  width: 100%;
  height: calc(100vh - var(--appPaddingTop));
}

:global(.isDemoMode) .container {
  height: calc(100vh - calc(var(--appPaddingTop) + var(--demoBannerHeight)));
}

.main {
  overflow: hidden;
  position: relative;
  display: grid;
  grid-template-columns: var(--subNavWidth) 1fr;
}

.content {
  display: grid;
  grid-template-columns: 235px 1fr;
  overflow: hidden;
}

.sidebar {
  padding: 20px 20px 0;
  border-right: 1px solid var(--gridLines);
  overflow: auto;
}
