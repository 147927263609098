.container {
  position: relative;
  max-height: 75vh;
}

.header {
  padding: 10px;
}

.content {
  color: var(--text);
  overflow: hidden;
  width: 375px;
  min-height: 100px;
}

.content.hasContent {
  padding-bottom: 45px;
}

.emptyMessage {
  padding: 10px;
}

.count {
  font-style: italic;
  font-weight: normal;
}

.footer {
  border-top: 1px solid var(--rowBorder);
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 45px;
}

.pagination {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
}

.pagination p {
  color: var(--rowHover);
  margin: 0 10px;
}

.pagination strong {
  color: var(--rowBorder);
  font-weight: normal;
}

.iconButton.isDisabled {
  cursor: not-allowed;
  opacity: 0.2;
}

.deleteAll {
  display: grid;
  position: absolute;
  right: 10px;
  bottom: 0;
  height: 100%;
  overflow: hidden;
}

.deleteAll.isConfirming {
  right: 0;
  width: 100%;
  height: 100px;
  overflow: visible;
  border-top: 1px solid var(--rowBorder);
}

.deleteAll p {
  color: var(--neutral);
  margin: 0;
  align-self: end;
}

.deleteAllLink {
  opacity: 1;
  padding: 1px;
  margin: 1px;
  transition: opacity 100ms;
}

.deleteAll.isConfirming .deleteAllLink {
  opacity: 0;
}

.confirmPrompt {
  display: grid;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  color: var(--text);
  background-color: var(--inputBackground);
}