.container {
  composes: darkPage from '../../styles/darkPage.css';
  color: var(--text)
}

.logo {
  composes: logo from '../../styles/logo.css';
}

.container ul {
  margin: 20px 0;
  padding: 0 20px;
}

.container li {
  margin: 5px 0;
}

.columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.column:first-of-type {
  padding: 10px 10px 10px 0;
  margin-right: 30px;
  position: relative;
}

.column:first-of-type::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 80%;
  background-color: var(--contentDarkBackground);
}

@media(max-width: var(--bpSmall)) {
  .columns {
    display: block;
  }

  .column:first-of-type {
    margin: 20px 0 0;
    padding: 0 0 20px;
    border-bottom: 1px solid var(--contentDarkBackground);
    text-align: center;
  }

  .column:first-of-type::after {
    display: none;
  }

  .container li {
    list-style-position: inside;
  }
}