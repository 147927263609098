.title {
  composes: headingLarge from '../../styles/type.css';
  letter-spacing: 1.5px;
  font-weight: normal;
}

.subtitle {
  font-size: 1.6rem;
  font-weight: bold;
  margin: 0 0 2px;
}

.subtitle > a {
  padding-left: 8px;
  font-weight: normal;
}

.smallText {
  font-size: 1.2rem;
}

.section {
  margin-top: 40px;
}

.sectionTitle {
  composes: headingMedium from '../../styles/type.css';
  color: var(--heading);
  text-align: left;
  margin: 0 0 15px;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.itemLabel,
.itemCell {
  padding: 5px;
  vertical-align: baseline;
}

.itemLabel {
  composes: label from '../../styles/type.css';
  margin: 0;
  padding-right: 10px;
}

.itemRow {
  padding: 5px 0;
  list-style: none;
}

.itemCell {
  color: var(--text);
}

.inlineLabel {
  composes: itemLabel;
  display: inline;
  margin-left: 10px;
}

.divider {
  padding-top: 20px;
  border-top: 1px solid #e3e8eb;
}

.editorContainer {
  composes: section;
  display: grid;
  grid-template-rows: 40px auto;
}

.nav {
  display: grid;
  grid-template-columns: auto auto auto;
  align-content: center;
}

.navButton {
  margin-right: 3px;
}