.container {
  display: grid;
  grid-template-columns: auto auto 1fr;
  align-items: center;
  align-self: start;
  margin-bottom: 10px;
}

.version {
  margin: 0 5px;
}

.iconGit {
  width: 20px;
}

.timestamp {
  text-transform: lowercase;
}