.container {
  background-color: var(--canvas);
  opacity: 0.9;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding-top: 50px;
  text-align: center;
  font-style: italic;
}

.error {
  color: var(--error);
}