@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes hide {
  0% {
    position: inherit;
    width: inherit;
    height: inherit;
  }

  100% {
    position: absolute;
    width: 0;
    height: 0;
    z-index: -1;
  }
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 200ms;
  animation-direction: forwards;
}

.fadeOut {
  --duration: 300ms;
  animation-name: fadeOut, hide;
  animation-duration: var(--duration), 0ms;
  animation-delay: 0ms, var(--duration);
  animation-timing-function: ease-out-back;
  animation-direction: forwards;
  animation-fill-mode: forwards;
}