.container {
  composes: darkPage from '../../styles/darkPage.css';
  color: hoverBackground
}

.container h3 {
  line-height: 1.5em;
}

.logo {
  composes: logo from '../../styles/logo.css';
}
