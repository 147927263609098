.container {
  padding-top: 10px;
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  justify-content: stretch;
}

.container.hasBorder {
  border-top: 1px solid var(--inputBorder);
  margin: 50px 0 0;
}

.container.hasSecondaryButton {
  grid-template-columns: minmax(max-content, auto) auto;
}

.container.hasTertiaryButton {
  grid-template-columns: auto auto auto;
}

/* Instances when the primary button is wrapped by a tooltip/generic div */
.container > div:last-child {
  width: 100%;
}

.secondaryButton {
  margin-left: 0;
  margin-right: 20px;
  width: calc(100% - 20px);
}
