.container {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  align-items: center;
  justify-items: center;
  align-content: center;
}

.spinner {
  width: 32px;
  color: var(--spinnerFill);
}
