.container {
  composes: content from '../stack/Stack.css';
}

.container.isGitless {
  display: block;
  background-size: 20px 20px;
  background-image: linear-gradient(to right, var(--gridLines) 1px, transparent 1px), linear-gradient(to bottom, var(--gridLines) 1px, transparent 1px);
  background-repeat: repeat;
  background-position: -1px -1px;
  overflow: auto;
}