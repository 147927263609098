.container {
  display: grid;
  grid-template-rows: auto auto;
  position: relative;
  align-items: start;
  text-align: center;
  border: 1px solid var(--gridLines);
  padding: 20px;
  margin: 0 2px 0 0;
  background-color: var(--canvas);
}

.container:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px
}

.container:last-child {
  margin-right: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px
}

.container.isLoading {
  grid-template-rows: auto;
}

.container h1 {
  display: grid;
  grid-template-rows: auto auto;
  margin: 0;
  text-shadow: 1px 1px rgba(255,255,255,0.2), -1px -1px rgb(227, 233, 235);
}

.container h1 span:first-child {
  font-size: 1.1em;
}

.container a {
  display: inline-block;
  margin: 10px 0 0;
  align-self: end;
  position: relative;
}

.iconChevron {
  height: 18px;
  width: auto;
  transform: translateY(-0.5px);
}

@media (--small-device) {
  .container {
    padding: 15px;
    margin-right: 0;
    margin-bottom: 2px;
  }

  .container:not(:last-child) {
    border-right-width: 1px;
    border-bottom: none;
  }

  .container:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }

  .container:last-child {
    margin-bottom: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 0;
  }

  .container h1 {
    display: block;
  }

  .container h1 span:first-child {
    margin-right: 8px;
  }
}

@media (prefers-color-scheme: dark) {
  .container h1 {
    text-shadow: none
  }
}
