.container {
  background-color: var(--canvas);
  color: var(--text);
  display: grid;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
}

.container.hasBorder {
  border: 1px solid var(--inputBorder);
}

.container.unSupported {
  height: 0;
  min-height: 0;
}

.editor {
  width: 100%;
  height: 100%;
}

.isLoading .editor,
.unSupported .editor {
  opacity: 0;
}

.container.isReadOnly {
  background-color: var(--canvasReadOnly);
}

.container.hasMinHeight {
  min-height: 100px;
}

/* This expands the ace editor, which by default has no width or height */
.editor > div {
  width: 100%;
  height: 100%;
}

.validator {
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 50%;
  opacity: 0;
}

.title {
  grid-row: 1;
}

.save {
  grid-row: 3;
  margin: 10px 0 20px;
}

.hasError {
  border: 1px solid red;
}

@media(max-width: var(--bpMedium)) {
  .container {
    width: 100%;
  }
}