.port {
  fill: var(--wire);
  pointer-events: none;
}

.highlighted {
  composes: port;
  fill: var(--nodeHighlight);
}

.portInner {
  composes: port;
  fill: var(--gridLines);
}

.background {
  fill: transparent;
}
