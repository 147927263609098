.container {
  display: block;
  width: 100%;
  min-height: 75px;
  border-radius: 2px;
  background-color: var(--inputBackground);
  border: 1px solid var(--inputBorder);
  padding: 10px;
  color: var(--text);
}

.hasError {
  border: 1px solid var(--error);
}
