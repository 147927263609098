.container {
  color: var(--inverse);
  border: 1px solid;
  border-radius: 2px;
  width: 100%;
  height: 35px;
  padding: 0;
  cursor: pointer;
  white-space: nowrap;
  transition: background-color 100ms;
}

.innerContainer {
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 5px 9px;
}

.childrenContainer {
  grid-row: 1;
  grid-column: 1;
}

.spinner {
  display: none;
  grid-row: 1;
  grid-column: 1;
  fill: var(--buttonIconFill);
}

.icon {
  fill: var(--buttonIconFill);
  width: 20px;
}

/* hasIcon modifier */

.hasIcon .innerContainer {
  padding-left: 5px;
}

.hasIcon .childrenContainer {
  display: grid;
  grid-template-columns: auto auto;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.hasIcon .text {
  margin-left: 5px;
}

.hasIcon svg {
  fill: var(--inverse);
}

/* Button modifiers */

.primary {
  background-color: var(--accent);
  border-color: var(--accent);
}

.primary,
.primary:visited {
  color: var(--inverse);
}

.primary:not(.isDisabled):hover {
  background-color: var(--buttonPrimaryHoverBackground);
}

.secondary {
  composes: container from './Link.css';
  display: inline-block;
  background-color: transparent;
  border-color: transparent;
  width: auto;
  margin: 0 10px;
}

.secondary,
.secondary:visited {
  color: var(--accent);
}

.warning {
  background-color: var(--error);
  border-color: var(--error);
}

.warning.isDisabled {
  background-color: var(--buttonWarningDisabledBackground);
  border-color: transparent;
  color: var(--canvas);
}

.warning:not(.isDisabled):hover {
  background-color: var(--buttonWarningHoverBackground);
}

.outline {
  background-color: transparent;
  border-color: var(--buttonPrimaryBackground);
  color: var(--buttonPrimaryBackground);
}

.outline.hasIcon svg {
  fill: var(--buttonPrimaryBackground);
}

.outline:not(.isDisabled):hover {
  border-color: var(--buttonPrimaryHoverBackground);
  color: var(--buttonPrimaryHoverBackground);
}

.outline:not(.isDisabled):hover svg {
  fill: var(--buttonPrimaryHoverBackground);
}

/* Button states */

.isDisabled,
.isDisabled[aria-disabled="true"] {
  cursor: default;
  color: var(--gridLines);
  border-color: var(--gridLines);
}

.isDisabled.hasIcon svg {
  fill: var(--buttonPrimaryDisabledText);
}

.primary.isDisabled,
.warning.isDisabled {
  color: var(--buttonPrimaryDisabledText);
}

.primary.isDisabled,
.warning.isDisabled,
.warning.isDisabled:hover {
  background-color: var(--buttonPrimaryDisabledBackground);
  border-color: transparent;
  color: var(--buttonPrimaryDisabledText);
}

.outline.isDisabled {
  color: var(--buttonOutlineDisabledText);
}

.outline.isDisabled.hasIcon svg {
  fill: var(--buttonOutlineDisabledText);
}

.secondary.isDisabled,
.secondary.isDisabled:hover {
  color: var(--neutral);
}

.isLoading {
  cursor: default;
}

.isLoading.primary {
  background-color: var(--buttonPrimaryLoadingBackground)
}

.isLoading .childrenContainer {
  visibility: hidden;
}

.isLoading .spinner {
  display: initial;
}