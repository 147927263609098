.container {
  display: grid;
  grid-template-rows: max-content auto;
  border: 1px solid var(--gridLines);
  box-shadow: 0px 8px 10px -11px var(--alpha-black-3);
  max-width: var(--bpXlarge);
}

.container:not(:last-child) {
  margin-bottom: 20px;
}

.header {
  background-color: var(--rowShade);
  border-bottom: 1px solid var(--gridLines);
  display: grid;
  grid-template-columns: auto max-content max-content max-content;
  align-items: center;
  padding: 10px 20px;
  box-shadow: 0px 3px 3px -3px var(--borderShadow);
}

.headerColumn {
  position: relative;
}

.headerColumn:not(:first-child) {
  justify-self: end;
}

.headerColumn:not(:last-child) {
  padding: 0 15px 0 0;
}

.headerColumn h2 {
  margin: 0;
}

.headerColumn p {
  color: var(--neutral);
  margin: 0;
  font-size: 1.4rem;
}

.heading {
  composes: label from '../../styles/type.css';
  color: var(--heading);
  display: inline-block;
  margin-right: 5px;
}

.status {
  display: grid;
  grid-template-columns: minmax(var(--bpXsmall), var(--bpXlarge)) minmax(var(--bpXsmall), var(--bpXlarge));
}

.statusColumn {
  padding: 20px 20px 30px;
}

.statusColumn:first-child {
  padding-right: 40px;
  border-right: 1px solid var(--gridLines);
}

.statusColumn:last-child {
  padding-left: 40px;
}

.deployment {
  background-color: var(--inputBackground);
  padding: 20px;
  margin: 0 0 10px;
  border-width: 1px;
  border-color: var(--gridLines);
  border-radius: 2px;
  text-align: center;
  height: 80px;
  display: grid;
  grid-template-columns: auto auto;
  align-content: center;
}

.deployment.prepared {
  border-style: dashed;
}

.deployment.deployed {
  border-style: solid;
}

.deployment.isEmpty {
  grid-template-columns: auto;
  border-color: var(--gridLines);
}

.deployment.isGitless {
  padding: 5px;
}

.viewLinks {
  display: grid;
  align-content: center;
  justify-content: right;
  border-left: 1px solid var(--gridLines);
  height: 100%;
  padding-right: 20px;
}

.viewLinks a {
  display: block;
}

.selector {
  display: grid;
  grid-template-columns: max-content auto;
  align-content: center;
  align-items: center;
}

.selector p {
  margin: 0 10px 0 0;
  color: var(--neutral);
}

.toggleButton {
  composes: container from '../core/Link.css';
  border: none;
}

.toggleButton.isDisabled {
  cursor: pointer;
  color: var(--neutral);
}

.toggleButton > span {
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
  justify-content: center;
}

.iconPlus {
  margin-right: 3px;
  width: 25px;
}

.emptyMessage {
  color: var(--neutral);
}

.emptyMessage p:last-child {
  margin: 0;
}

.message {
  padding: 20px;
  font-size: 1.6rem;
}