.container {
  composes: contentCell from '../../styles/contentCell.css';
  margin: auto;
}

.iconCircle {
  position: relative;
  top: -2px;
  left: 5px;
  width: 13px;
  height: 13px;
}

.iconError,
.iconCheckmark {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.iconCheckmark {
  position: relative;
  top: -1px;
  fill: var(--accent);
}

@media (--large-device) { 
  .container {
    width: 90%;
  }
}