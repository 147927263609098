.container {
  margin: 0 0 10px;
  padding: 0;
  list-style: none;
  position: relative;
  align-self: start;
}

.item {
  display: grid;
  grid-template-columns: 23px 1fr auto;
  align-items: center;
  position: relative;
  margin: 0 0 0 25px;
}

.item:last-child {
  margin-bottom: 0;
}

.item:before,
.item:after {
  content: '';
  position: absolute;
  left: -16px;
  background-color: var(--neutral);
}

.item:before {
  width: 15px;
  height: 1px;
  top: calc(50% + 1px);
}

.item:after {
  width: 1px;
  height: 100%;
  top: 0;
}

.item:first-child:after {
  top: -7px;
  height: calc(100% + 10px);
}

.item:last-child:after {
  height: calc(50% + 1px);
}

.item:only-child:after {
  top: -5px;
  height: calc(50% + 6px);
}

.iconSpinner {
  width: 19px;
  fill: var(--spinnerFill);
  padding-top: 3px;
  margin: 0 1px;
}

.iconCheckmark {
  fill: var(--accent);
  width: 19px;
}

.iconClear {
  fill: var(--error);
  width: 19px;
}