.container {
  background-color: var(--inputBackground);
  overflow: auto;
  position: relative;
}

.container.noScroll {
  overflow: hidden;
}

.message {
  color: var(--neutral);
  padding: 20px;
}
