.container {
  list-style: none;
  margin: 0;
  padding: 8px;
  font-family: 'Monaco',Inconsolata,courier,monospace;
  position: relative;
  border-radius: 4px;
}

.container:after {
  content: '';
  position: absolute;
  opacity: 0;
  top: -20px;
  right: 8px;
  color: var(--neutral);
  font-size: 1.1rem;
  font-family: sans-serif;
  transition: opacity 200ms;
}

.container.isClicked:after {
  content: 'copied!';
  opacity: 1;
}

.container.isHovered:after {
  content: 'click to copy';
  opacity: 1;
}

.line {
  cursor: pointer;
  font-size: 1.4rem;
  position: relative;
  padding: 8px;
  border-radius: 1px;
  text-align: left;
  white-space: pre-wrap;
}

.line:not(:last-of-type) {
  margin-bottom: 10px;
}

.line.hasPrompt {
  padding-left: 20px;
}

.line.hasPrompt:before {
  content: '$';
  position: absolute;
  left: 5px;
  top: 9px;
}

.line pre {
  margin: 0;
}


/* Browser mockup code
 * Contribute: https: //gist.github.com/jarthod/8719db9fef8deb937f4f
 * Live example: https: //updown.io
 */
.hasChrome {
  border-top: 35px solid var(--codeBlockBar);
  box-shadow: 5.665px 9.429px 35px 0px rgba(0, 0, 0, 0.2);
}

.container.hasChrome:before {
  display: block;
  position: absolute;
  content: '';
  top: -20px;
  left: 20px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #f44;
  box-shadow: 0 0 0 2px #f44, 1.5em 0 0 2px #9b3, 3em 0 0 2px #fb5;
}

/* theme .light */

.container.light {
  background-color: var(--contentLightBackground);
}

.container.light .line {
  color: var(--contentLightText);
}

.container.light .line:hover {
  background-color: var(--contentLightHoverBackground);
  color: var(--contentLightHoverText);
}

/* theme .dark */

.container.dark {
  background-color: var(--contentDarkBackground);
}

.container.dark .line {
  color: var(--contentDarkText);
}

.container.dark .line:hover {
  background-color: var(--contentDarkHoverBackground);
  color: var(--contentDarkHoverText);
}

/* theme .default */

.container.default {
  background-color: var(--rowShade);
}

.container.default .line {
  color: var(--text);
}

.container.default:hover {
  background-color: var(--rowHover);
}