.container {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: 1fr;
  padding-top: var(--appPaddingTop);
  overflow: hidden;
}

:global(.isDemoMode) {
  padding-top: calc(var(--appPaddingTop) + var(--demoBannerHeight));
}

:global(.isChromeless) {
  padding-top: 0;
}

.content {
  min-width: 0;
  min-height: 0;
  overflow: auto;
}

.hasModal {
  overflow: hidden;
}
