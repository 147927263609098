:global(.react-autosuggest__container) {
  cursor: default;
  position: relative;
  color: var(--inputText);
}

:global(.react-autosuggest__input) {
  background-color: var(--inputBackground);
  border: 1px solid var(--inputBorder);
  border-radius: 2px;
  padding: 8px;
  width: 100%;
}

:global(.react-autosuggest__input--open) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

:global(.react-autosuggest__input:focus ) {
  outline: none;
}

:global(.react-autosuggest__suggestions-list) {
  background-color: var(--inputBackground);
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
  max-height: calc(100vh / 3);
  overflow: auto;
  border-radius: 2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid var(--inputBorder);
  border-width: 0 1px 1px;
}

:global(.react-autosuggest__suggestions-container--open .react-autosuggest__suggestions-list) {
  z-index: 5;
  box-shadow: 0px 10px 20px -10px var(--modalShadow);
}

:global(.react-autosuggest__suggestion) {
  cursor: pointer;
  color: var(--neutral);
  padding: 5px 9px;
}

:global(.react-autosuggest__suggestion--highlighted) {
  color: var(--accent);
}