.container {
  display: grid;
  grid-template-columns: auto auto;
  position: absolute;
  right: 0;
  bottom: 0;
}

.iconChevron {
  width: 20px;
  height: auto;
}

.link {
  composes: smallCaps from '../../styles/type.css';
  font-weight: normal;
  background-color: var(--rowShade);
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  margin-left: 1px;
  padding: 3px;
  border: none;
}

.link:first-child {
  border-radius: 2px 0 0 2px;
  padding-right: 10px;
}

.link:last-child {
  border-radius: 0 2px 2px 0;
  padding-left: 10px;
}

.link:not(.inactiveLink):hover {
  background-color: var(--rowHover);
}

.inactiveLink {
  cursor: not-allowed;
  color: var(--neutral);
}

.inactiveLink .iconChevron,
.inactiveLink > span {
  opacity: 0.2;
}
