.container {
  position: fixed;
  right: var(--fixedToolRightPosition);
  top: var(--fixedToolTopPosition);
  background-color: var(--inputBackground);
  border: 1px solid var(--inputBorder);
  padding: 10px;
  width: 200px;
  border-radius: 2px;
  box-shadow: 1px 1px 11px var(--defaultContainerShadow);
}

:global(.isDemoMode) .container {
  top: calc(var(--fixedToolTopPosition) + var(--demoBannerHeight));
}

.heading {
  composes: label from '../../styles/type.css';
}

.rangeList {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0 0 0 -7px;
}

.rangeListItem > button {
  border: none;
  cursor: pointer;
  color: var(--text);
}

.rangeListItemSelected > button {
  font-weight: bold;
}
