.container {
  margin: 0;
}

.label {
  composes: smallCaps from '../../styles/type.css';
  display: inline-block;
  color: var(--neutral);
}

.description {
  display: inline-block;
  margin: 0 15px 0 3px;
}

.description:last-of-type {
  margin-right: 0;
}