.container {
  display: grid;
  grid-template-rows: auto auto;
  align-items: center;
  align-content: center;
  padding: 0 20px;
  border-bottom: 1px solid var(--gridLines);
}

.title {
  display: inline-block;
  color: var(--heading);
  margin: 0 0 3px;
  padding-right: 50px;
  position: relative;
  z-index: 1;
}

.description {
  margin: 0;
}
