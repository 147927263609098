.container {
  display: grid;
  grid-template-rows: auto auto;
  align-items: center;
  align-content: center;
  padding: 0 20px;
  border-bottom: 1px solid var(--gridLines);
}

.container p {
  margin: 0;
}

.title,
.repo {
  display: inline-block;
}

.title {
  color: var(--heading);
  margin: 0 0 3px;
  padding-right: 50px;
  position: relative;
  z-index: 1;
}

.repo {
  display: grid;
  grid-template-columns: 17px max-content;
  align-items: center;
}

.repo svg {
  width: 13px;
  height: auto;
}