.container {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: var(--alpha-blue-9);
  text-align: center;
  display: grid;
  justify-content: center;
  align-content: center;
}