.container {
  cursor: pointer;
  padding: 10px;
  border-radius: 2px;
}

.container:hover,
.container:focus,
.container:focus-visible,
.container:focus-within,
.container:active {
  background-color: var(--buttonTransparentBackground);
}

.container :global(.MuiTouchRipple-root) {
  border-radius: 2px;
}

.container.focused :global(.MuiTouchRipple-root) {
  opacity: 0;
}

.icon {
  width: auto;
  fill: var(--neutral);
  color: var(--neutral);
}

.container:hover .icon {
  fill: var(--accent);
  color: var(--accent);
}

.small .icon {
  font-size: 18px;
}

.medium .icon {
  font-size: 24px;
}

.large .icon {
  font-size: 30px;
}
