.container {
  box-shadow: none;
  position: relative;
}

.group {
  composes: primary from './Button.css';
  border-radius: 0;
}

.group:hover {
  background-color: var(--buttonPrimaryHoverBackground);
  box-shadow: none;
}

.primary {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  text-transform: none;
  font-size: 1.5rem;
  position: static;
  padding: 5px 9px;
}

.primary:not(:last-child) {
  border-color: var(--inputBorder);
}

.label {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}

.toggle {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  padding: 0;
}

.spinner {
  background-color: var(--buttonPrimaryHoverBackground);
  border: none;
  border-radius: 2px;
  position: absolute;
  z-index: 1;
}

.icon,
.spinner svg {
  fill: var(--buttonIconFill);
}