/* prettier-ignore */
@import './styles/type.css';
@import './styles/colors.css';
@import './styles/dimensions.css';

html,
body {
  height: 100%;
  font-size: 62.5%;
}

body {
  color: var(--text);
  background-color: var(--body);
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
  overflow-x: hidden;
}

* {
  outline: none;
  box-sizing: border-box;
}

:global(.isTabbing) *:focus {
  outline: 1px dotted var(--neutral);
}

a:not([class]),
a:not([class]):visited {
  color: var(--link);
  text-decoration: none;
}

a:not([class]):hover {
  color: var(--linkHover);
}

a[target='_blank']:not([class]):after {
  content: 'open_in_new';
  position: relative;
  top: 2px;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.4rem;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'liga';
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p:not([class]) {
  line-height: 1.9rem;
}

pre {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
  line-height: 1.9rem;
  white-space: normal;
}

ul:not([class]),
ol:not([class]) {
  padding: 0;
  margin: 20px 0 20px 20px;
}

ul:not([class]) > li,
ol:not([class]) > li {
  margin: 0 0 6px;
}

/* CSSTransition states */

:global(.isHidden) {
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  pointer-events: none;
}

:global(.enter) {
  opacity: 0.01;
}

:global(.enterActive) {
  opacity: 1;
  transition: opacity 200ms;
}

:global(.exit) {
  opacity: 1;
  transition: opacity 200ms ease-out;
}

:global(.exitActive) {
  opacity: 0.01;
  transition: opacity 200ms ease-out;
}

/* styles for editable list widget */

:global(.red-ui-editableList-container) {
  border-radius: 4px;
  padding: 5px;
  margin: 0;
  vertical-align: middle;
  box-sizing: border-box;
}
:global(.red-ui-editableList-container) :global(.red-ui-editableList-list) {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}
:global(.red-ui-editableList-container) :global(.red-ui-editabelList-item-placeholder) {
  border: 2px dashed #f0f0f0 !important;
}
:global(.red-ui-editableList-container) li {
  display: flex;
  background: #fff;
  margin: 0;
  padding: 8px 0px;
  min-height: 20px;
}
:global(.red-ui-editableList-container) li :global(.red-ui-editableList-item-handle) {
  flex: 0 0 auto;
  color: #eee;
  cursor: move;
}
:global(.red-ui-editableList-container) li :global(.red-ui-editableList-item-content) {
  display: flex;
  flex: 1 1 auto;
}
:global(.red-ui-editableList-container) li :global(.red-ui-editableList-item-content) * {
  flex: 1 1 auto;
}
:global(.red-ui-editableList-container) li :global(.red-ui-editableList-item-content) input[type="text"]:first-of-type {
  margin-right: 5px;
}
:global(.red-ui-editableList-container) li :global(.red-ui-editableList-item-remove) {
  flex: 0 0 auto;
  border: none;
}
:global(.red-ui-editableList-container) li :global(.red-ui-editableList-item-remove)::after {
  content: "X";
}
:global(.red-ui-editableList-container) li:global(.ui-sortable-helper) {
  border-top: 1px solid #f0f0f0;
}
:global(.red-ui-editableList-container) li:global(.red-ui-editableList-item-deleting) {
  background: #fee;
}
:global(.red-ui-editableList-container) li:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}

:global(.red-ui-node-info-container) {
  margin-bottom: 20px;
}

:global(.red-ui-node-info-header) {
  font-size: 2.5rem;
}

:global(.red-ui-node-info-cell),
:global(.red-ui-node-info-label) {
  padding: 6px;
}

:global(.red-ui-node-info-cell) {
}

:global(.red-ui-node-info-label) {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.1rem;
  padding-left: 0;
  padding-top: 9px;
}
